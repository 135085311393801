import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import { type ChannelListQueryParamsType } from '@sendbird/uikit-react/GroupChannelList/context';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { getActiveChannelUrl, getShowNewMessage } from '../selectors';
import { ChannelListItem } from './ChannelListItem';
import { setActiveChannelUrl, setReplyMessageId, setShowMessageSearch } from '../actions';
import { ChannelListHeader } from './ChannelListHeader';

const classes = {
    groupChannelList: (activeChannel: string, showNewMessage: boolean) =>
        clsx('sm:flex', 'sm:flex-shrink-0', 'sm:w-[320px]', { hidden: !!activeChannel || showNewMessage }),
};

export const ChannelList = () => {
    const dispatch = useDispatch();
    const activeChannel = useSelector(getActiveChannelUrl);
    const showNewMessage = useSelector(getShowNewMessage);
    const { isMobile } = useIsMobileMixin();

    return (
        <GroupChannelList
            channelListQueryParams={{
                includeEmpty: true,
                order: 'latest_last_message' as ChannelListQueryParamsType['order'],
            }}
            className={classes.groupChannelList(activeChannel, showNewMessage)}
            onChannelCreated={groupChannel => {
                dispatch(setActiveChannelUrl(groupChannel.url));
                dispatch(setReplyMessageId(null));
            }}
            disableAutoSelect={showNewMessage ? true : isMobile ? true : !!activeChannel}
            onChannelSelect={groupChannel => {
                if (groupChannel?.url) {
                    dispatch(setShowMessageSearch(false));
                    dispatch(setActiveChannelUrl(groupChannel.url));
                    dispatch(setReplyMessageId(null));
                }
            }}
            renderHeader={() => <ChannelListHeader />}
            selectedChannelUrl={activeChannel}
            renderChannelPreview={p => <ChannelListItem key={p.channel.url} {...p} />}
            renderPlaceHolderLoading={() => <FrontRoyalSpinner color="force-brand" />}
        />
    );
};
