/* eslint-disable func-names */
import { AppBrandConfigs, targetBrandConfig } from 'AppBranding';
import ClientStorage from 'ClientStorage';

import { storeProvider } from 'ReduxHelpers';
import { themeActions } from 'Theme/redux/theme';
import angularModule from './navigation_module';

/**
 * Singleton view model for the app's header.
 * Inject into states to allow them to adjust things like the back button's URL and visibility
 */
angularModule.factory('Navigation.AppHeader.AppHeaderViewModel', [
    '$injector',
    $injector => {
        const AClassAbove = $injector.get('AClassAbove');
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const $timeout = $injector.get('$timeout');
        const safeApply = $injector.get('safeApply');
        const SiteMetadata = $injector.get('SiteMetadata');
        const $rootScope = $injector.get('$rootScope');
        const $sce = $injector.get('$sce');
        const Capabilities = $injector.get('Capabilities');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ConfigFactory = $injector.get('ConfigFactory');
        const scrollHelper = $injector.get('scrollHelper');

        const AppHeaderViewModel = AClassAbove.subclass(function () {
            const translationHelper = new TranslationHelper('navigation.app_header_view_model');

            let BEGIN_LESSON_INSTRUCTIONS;
            let BEGIN_TEST_INSTRUCTIONS;
            let END_LESSON_INSTRUCTIONS;
            let END_TEST_INSTRUCTIONS;
            let SCREEN_COMPLETE_INSTRUCTIONS;
            let TRY_AGAIN;

            this.refreshTranslations = () => {
                BEGIN_LESSON_INSTRUCTIONS = translationHelper.get('begin_lesson');
                BEGIN_TEST_INSTRUCTIONS = translationHelper.get('begin_test');
                END_LESSON_INSTRUCTIONS = translationHelper.get('lesson_complete');
                END_TEST_INSTRUCTIONS = translationHelper.get('test_complete');
                SCREEN_COMPLETE_INSTRUCTIONS = translationHelper.get('screen_complete');
                TRY_AGAIN = translationHelper.get('try_again');
            };
            // Note: we don't need to call this.refreshTranslations(); here because it will be called for us by
            // addPartAndRefreshTranslationTables() in translation_module.js, which in turn is called by
            // loadGlobalDependencies() in route_asset_loader.js

            Object.defineProperty(this.prototype, 'layout', {
                get() {
                    const $route = $injector.get('$route');
                    let headerLayout;
                    try {
                        headerLayout = $route.current.$$route.headerLayout;
                        // eslint-disable-next-line no-empty
                    } catch (e) {}

                    // this was originally the default, so specs were written
                    // with this assumption.  Not sure if this is ever really
                    // hit in the wild, but if so it should continue to
                    // behave as it always has.
                    if (!headerLayout) {
                        headerLayout = 'learner';
                    }

                    const user = $rootScope.currentUser;

                    if (headerLayout !== 'default') {
                        return headerLayout;
                    }
                    if (!user) {
                        return 'learner';
                    }
                    return 'learner';
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'frameInstructions', {
                get() {
                    if (!this.playerViewModel) {
                        return null;
                    }

                    if (this.playerViewModel.showStartScreen) {
                        return this.playerViewModel.lesson.test ? BEGIN_TEST_INSTRUCTIONS : BEGIN_LESSON_INSTRUCTIONS;
                    }

                    if (this.playerViewModel.showFinishScreen && this.playerViewModel.lessonFailed) {
                        return TRY_AGAIN;
                    }

                    if (this.playerViewModel.showFinishScreen) {
                        return this.playerViewModel.lesson.test ? END_TEST_INSTRUCTIONS : END_LESSON_INSTRUCTIONS;
                    }

                    if (
                        this.playerViewModel.activeFrameViewModel &&
                        this.playerViewModel.activeFrameViewModel.complete
                    ) {
                        return SCREEN_COMPLETE_INSTRUCTIONS;
                    }

                    if (this.playerViewModel.activeFrameViewModel) {
                        return this.playerViewModel.activeFrameViewModel.frame.miniInstructions;
                    }

                    return null;
                },
            });

            let _currentFrameInstructionsForView = '';
            let _currentFrameInstructionsDelaying = false;

            // If frame instructions have changed since last time, we want the view to clear itself first,
            // then after a tiny delay show the new instructions. So, we track the last frame instruction value
            // and manually delay returning the new value.
            Object.defineProperty(this.prototype, 'frameInstructionsForView', {
                get() {
                    let valueToReturn = '';

                    // If a delay is in progress, just continue to return blank
                    if (_currentFrameInstructionsDelaying) {
                        valueToReturn = '';
                        // If the value has changed since last time, schedule a new delayed update
                    } else if (_currentFrameInstructionsForView !== this.frameInstructions) {
                        // After short delay, set the appropriate new value
                        let delay = 10;

                        // special case: if coming from an end screen, add more delay
                        // this gives time for the exit animations to complete from those screens

                        if (
                            [
                                BEGIN_LESSON_INSTRUCTIONS,
                                BEGIN_TEST_INSTRUCTIONS,
                                END_LESSON_INSTRUCTIONS,
                                END_TEST_INSTRUCTIONS,
                            ].includes(_currentFrameInstructionsForView)
                        ) {
                            delay = 1500;
                        }

                        // Make a note of the fact that we're delaying now
                        _currentFrameInstructionsDelaying = true;
                        // Trigger the delay
                        $timeout(() => {
                            // Update the current frame instructions for the view and trigger a scope apply
                            safeApply($rootScope, () => {
                                _currentFrameInstructionsForView = this.frameInstructions;
                                _currentFrameInstructionsDelaying = false;
                            });
                        }, delay);

                        valueToReturn = '';
                        // In the steady state, just return the current value
                    } else {
                        valueToReturn = _currentFrameInstructionsForView;
                    }

                    return valueToReturn;
                },
            });

            Object.defineProperty(this.prototype, 'showAlternateHomeButton', {
                get() {
                    return this._showAlternateHomeButton;
                },
                set(value) {
                    // HACK: delay the setter to avoid bizarre ng-animate problems
                    // We can try changing this back to a simple setter when ng-animate gets further fixes?
                    // Relevant ticket: https://trello.com/c/96IkOFuU/189-bug-edit-lesson-then-exit-using-back-button-back-button-looks-crazy-in-header
                    $timeout(() => {
                        safeApply($rootScope, () => {
                            this._showAlternateHomeButton = value;
                        });
                    }, 10);
                },
            });

            Object.defineProperty(this.prototype, 'bodyBackgroundColor', {
                get() {
                    const bodyClass = $('body').attr('class') || '';
                    return (bodyClass.match(/bg-(\w+)/) || [])[1];
                },
            });

            Object.defineProperty(this.prototype, 'hasTopMessage', {
                get() {
                    return this._hasTopMessage ?? false;
                },
                set(val) {
                    this._hasTopMessage = !!val;
                },
            });

            Object.defineProperty(this.prototype, 'showCaret', {
                get() {
                    return this._showCaret ?? false;
                },
                set(val) {
                    this._showCaret = !!val;
                },
            });

            Object.defineProperty(this.prototype, 'hideMobileMenu', {
                get() {
                    if (this.playerViewModel) return true;
                    if (this._mobileKeyboardShowing) return true;
                    return false;
                },
            });

            // selectors used during background updates
            const bodyElem = $('body');
            const htmlElem = $('html');
            const metaThemeColorElem = $('meta[name=theme-color]');
            const msapplicationTileColor = $('meta[name=msapplication-TileColor]');

            return {
                initialize() {
                    // these are public attributes that can
                    // be set from outside to control the behavior of
                    // the app header
                    this._showAlternateHomeButton = false;
                    this.animateBackTransition = true;
                    this.showFrameInstructions = true;
                    this.preventFrameInstructions = false;
                    this.showMobileMessages = true;
                    this.textRows = undefined;
                    this.playerViewModel = undefined;
                    this.setTitleHTML();
                    this.allowTapScroll = Capabilities.touchEnabled;
                },

                toggleVisibility(visible) {
                    if (!visible) {
                        $('body').addClass('header-invisible');
                    } else {
                        $('body').removeClass('header-invisible');
                    }
                },

                goToMarketingPages() {
                    $window.location.href = '/';
                },

                goHome() {
                    // No need to reload if already home
                    const homePath = $rootScope.homePath;
                    if ($location.url() !== '/' && $location.url() !== homePath) {
                        $location.url($rootScope.homePath);
                        SiteMetadata.updateHeaderMetadata();
                    }
                },

                goBack() {
                    // see NavigationModule
                    $rootScope.back(this.animateBackTransition);
                },

                backgroundColors: {
                    // keys are background colors, values are
                    // associated text colors

                    // BACKGROUND_COLOR: TEXT_COLOR
                    blue: 'white',
                    purple: 'white',
                    coral: 'eggplant',
                    turquoise: 'white',

                    // 'eggplant': 'white',
                    // 'plum': 'white',

                    landing: 'white',

                    'completion-blue': 'white',

                    white: '',
                    beige: '',
                    'beige-pattern': '',
                    'demo-pattern': '',
                },

                setBodyBackground(targetColor) {
                    // add the 'bg-TARGET_COLOR' class to sp-page
                    // and remove any other ones.  Also set the
                    // background on the header to the same color (
                    // I tried just making it transparent, but the
                    // transition looks better this way)

                    // attempt to prevent weird Safari bug during iframe unloads
                    if (!Object || !Object.keys) {
                        return;
                    }

                    const colors = Object.keys(this.backgroundColors);

                    targetColor = targetColor || 'beige';

                    // Since the store might not always be defined in specs, we add the
                    // elvis operator here.
                    storeProvider.store?.dispatch(themeActions.setBgColor({ bgColor: targetColor }));

                    if (!colors.includes(targetColor)) {
                        throw new Error(`unsupported background color "${targetColor}"`);
                    }
                    const isNotchDevice = htmlElem.hasClass('notch');

                    // clear out stale background, but don't remove the color if we're about to just re-add it (thrashing)
                    colors.forEach(color => {
                        if (color === targetColor) {
                            return;
                        }
                        bodyElem.removeClass(`bg-${color}`);
                        if (isNotchDevice) {
                            htmlElem.removeClass(`bg-${color}`);
                        }
                    });
                    bodyElem.addClass(`bg-${targetColor}`);

                    // The appShell directive is responsible for instantiating the AppHeaderViewModel singleton
                    // and it only does so AFTER calling ConfigFactory.getConfig(), so it should be fine to call
                    // ConfigFactory.getSync() here.
                    const config = ConfigFactory.getSync();

                    // This is putting the brand class on the body element. As we transition to react, we eventually
                    // won't need this anymore. Instead, we're setting the brand class in ReactWrapper.
                    let _targetBrandConfig = targetBrandConfig($rootScope.currentUser, config);
                    const serverBrand = ClientStorage.getItem('serverBrand');
                    if (serverBrand && serverBrand in AppBrandConfigs && !$rootScope.currentUser) {
                        _targetBrandConfig = AppBrandConfigs[serverBrand];
                    }
                    Object.values(AppBrandConfigs).forEach(brandConfig => {
                        if (brandConfig.brandStyleClass !== _targetBrandConfig.brandStyleClass) {
                            bodyElem.removeClass(brandConfig.brandStyleClass);
                        }
                    });
                    bodyElem.addClass(_targetBrandConfig.brandStyleClass);
                    metaThemeColorElem.attr('content', _targetBrandConfig.themeColor);
                    msapplicationTileColor.attr('content', _targetBrandConfig.themeColor);

                    // Color the `html` element inset notches in some devices (unibrow header and home-drag indicator footer).
                    // Note that these html-element background colors are limited to content views, where the Coral coloring
                    // clashes. Some other non-content colors (beige, etc) default to the Coral wrapping color, because
                    // this matches the mobile menu button colors, whereas a continuation of the body background color would
                    // clash. Additionally, beyond visually clashing, patterned image backgrounds would be hard to align between
                    // html and body elements.
                    if (isNotchDevice && ['blue', 'purple', 'turquoise', 'completion-blue'].includes(targetColor)) {
                        htmlElem.addClass(`bg-${targetColor}`);
                    } else {
                        Object.values(AppBrandConfigs).forEach(brandConfig => {
                            if (
                                brandConfig.defaultNotchBackgroundClass !==
                                _targetBrandConfig.defaultNotchBackgroundClass
                            ) {
                                htmlElem.removeClass(brandConfig.defaultNotchBackgroundClass);
                            }
                        });
                        htmlElem.addClass(_targetBrandConfig.defaultNotchBackgroundClass);
                    }
                },

                scrollToTop(smooth) {
                    scrollHelper.scrollToTop(smooth);
                },

                backButtonClick() {
                    if (this.playerViewModel) {
                        // When there is a lesson, back-button-click will have a label.  In practice
                        // mode it will not.  Probably it should have the label 'practice' or something,
                        // but doesn't seem worth custom implementation
                        this.playerViewModel.log('lesson:back-button-click');
                        this.playerViewModel.gotoPrev();
                        document.activeElement.blur();
                    } else {
                        throw new Error('backButtonClick cannot be called without a playerViewModel.');
                    }
                },

                exitButtonClick() {
                    // We should have a PlayerViewModel most of the time
                    if (this.playerViewModel) {
                        if (this.playerViewModel.previewMode) {
                            return;
                        }

                        if (this.playerViewModel.demoMode) {
                            $window.parent.postMessage('closeDemo', '*');
                        }
                        // And will usually exit to its dashboard
                        else if (this.playerViewModel.stream) {
                            this.setBodyBackground('beige');
                            $location.url(this.playerViewModel.stream.streamDashboardPath);
                            SiteMetadata.updateHeaderMetadata();
                        } else {
                            this.goBack();
                        }
                    }
                    // Unless we are transitioning between lessons

                    // In which case we cached the stream from the last PlayerViewModel
                    else if (this.lastPlayedStream) {
                        // So exit to it
                        $location.url(this.lastPlayedStream.streamDashboardPath);
                        SiteMetadata.updateHeaderMetadata();
                    } else {
                        // It's inconclusive what state the user would have to be in for them to hit this point,
                        // but in the event that they do, just send them back from whence they came.
                        this.goBack();
                    }
                },

                demoLogoClick() {
                    // we're loaded in an iframe, so we ask the parent window to navigate up the page
                    $window.parent.postMessage('scrollUpPage', '*');
                },

                setTitleHTML(titleHTML, opts = {}) {
                    if (angular.isDefined(titleHTML)) {
                        $sce.trustAsHtml(titleHTML);
                        this.title = titleHTML;
                    } else if ($rootScope.currentUser && $rootScope.currentUser.name) {
                        this.title = $rootScope.currentUser.name.toUpperCase();
                    } else {
                        this.title = undefined;
                    }

                    this.titleOpts = opts;
                },

                refreshTranslations() {
                    AppHeaderViewModel.refreshTranslations();
                },

                // It seems like it might be dangerous if this were called twice without calling cancel
                // in between, since you'd be setting up the same listeners twice, but in practice it
                // shouldn't be a problem both because
                // * we just never do that
                // * it doesn't hurt anything to have the same listeners twice here, and each caller will have a cancel
                //      function for cleaning up its own listeners
                //
                // In cordova, the app-shell is apparently sometimes removed and re-added during registration, so
                // it has to be possible to call this method repeatedly.
                manageMobileMenuVisibility() {
                    const onKeyboardDidHide = () => {
                        this._mobileKeyboardShowing = false;
                        $rootScope.$apply();
                    };
                    const onKeyboardDidShow = () => {
                        this._mobileKeyboardShowing = true;
                        $rootScope.$apply();
                    };

                    $window.addEventListener('keyboardDidHide', onKeyboardDidHide);
                    $window.addEventListener('keyboardDidShow', onKeyboardDidShow);

                    const cancel = () => {
                        $window.removeEventListener('keyboardDidHide', onKeyboardDidHide);
                        $window.removeEventListener('keyboardDidShow', onKeyboardDidShow);
                    };
                    return cancel;
                },
            };
        });

        // Singleton
        return new AppHeaderViewModel();
    },
]);
