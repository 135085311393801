import { useErrorLogService } from 'ErrorLogging';
import { FlatButton } from 'FlatButton';
import { type ErrorHandlingComponentParams } from 'FrontRoyalErrorBoundary';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Typography } from 'Typography';
import { isRetriableNetworkError } from 'ReduxHelpers';
import NetworkConnection from 'NetworkConnection';
import { reset } from '../actions';

const isNetworkError = (error: unknown) => {
    if (isRetriableNetworkError(error)) return true;

    // Right now we are not throwing network errors from sendbird.
    // If we want to handle specific ones we can use SendbirdErrorCode to check for statuses

    return false;
};

export const ErrorMessage = ({
    resetErrorBoundary,
    error,
    show = true,
}: ErrorHandlingComponentParams & { show?: boolean }) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging.messaging' });
    const dispatch = useDispatch();
    const ErrorLogService = useErrorLogService();

    useEffect(() => {
        if (error) ErrorLogService.notify(String(error), undefined, {});
    }, [error, ErrorLogService]);

    const onClick = useCallback(() => {
        dispatch(reset());
        resetErrorBoundary();
    }, [dispatch, resetErrorBoundary]);

    if (isNetworkError(error)) {
        // If one of our api calls failed and we ended up offline
        // don't show the error modal as we will likely disable the
        // modal anyways
        if (NetworkConnection.offline) return null;
    }

    return show ? (
        <div className="mx-auto mt-16">
            <Typography variant="header2" className="mb-4 text-center text-[40px] font-semibold text-black">
                {t('somethingWentWrong')}
            </Typography>
            <Typography variant="smallText" className="mb-3 text-center text-2xl font-semibold text-black">
                {t('engineersNotified')}
            </Typography>
            <div className="flex items-center justify-center">
                <FlatButton onClick={onClick} className="border-slate-grey-700 bg-slate-grey-500">
                    {t('closeAndRetry')}
                </FlatButton>
            </div>
        </div>
    ) : null;
};
