import { type Stream } from 'Lessons';
import { memo, type FC } from 'react';
import { type FrameGroup } from './CoverageMaps.types';
import { FrameLinkList } from './FrameLinkList';

function buildFrameGroups({
    streams,
    instructionalFrameIds,
}: {
    streams: Stream<true>[];
    instructionalFrameIds: string[];
}): FrameGroup[] {
    const lessons = streams.flatMap(stream => stream.lessons);
    const frameGroups = instructionalFrameIds.reduce((acc: FrameGroup[], frameId: string) => {
        const lesson = lessons.find(candidateLesson =>
            candidateLesson.frames.some(lessonFrame => lessonFrame.id === frameId),
        );
        if (!lesson) throw new Error('Could not find lesson for frame.');
        const stream = streams.find(candidateStream => candidateStream.lessons.some(l => l.id === lesson.id))!;
        const frame = lesson.frames.find(candidateFrame => candidateFrame.id === frameId);
        if (!frame) throw new Error('Could not find frame');
        let frameGroup = acc.find(candidateFrameGroup => candidateFrameGroup.lesson === lesson);
        if (!frameGroup) {
            frameGroup = {
                stream,
                lesson,
                lessonIndexInPlaylist: lessons.indexOf(lesson),
                frameDescriptors: [],
            };
            acc.push(frameGroup);
        }
        frameGroup.frameDescriptors.push({
            frameId: frame.id,
            lessonId: lesson.id,
            frameIndex: lesson.frames.indexOf(frame),
        });
        frameGroup.frameDescriptors.sort((a, b) => (a.frameIndex > b.frameIndex ? 1 : -1));
        return acc;
    }, [] as FrameGroup[]);
    frameGroups.sort((a, b) => (a.lessonIndexInPlaylist > b.lessonIndexInPlaylist ? 1 : -1));
    return frameGroups;
}

type Props = { frameIds: string[]; streams: Stream<true>[] };

export const FrameLinkListByLesson: FC<Props> = memo(({ frameIds, streams }) => {
    const frameGroups = buildFrameGroups({ streams, instructionalFrameIds: frameIds });

    return (
        <ul>
            {frameGroups.map(frameGroup => {
                const stream = streams.find(candidateStream => candidateStream.id === frameGroup.stream.id);
                return (
                    <li key={frameGroup.lesson.id} className="mb-3 list-disc">
                        <FrameLinkList
                            lesson={frameGroup.lesson}
                            frameEntries={frameGroup.frameDescriptors}
                            stream={stream!}
                        />
                    </li>
                );
            })}
        </ul>
    );
});
