/* eslint-disable max-lines-per-function */
import { type FileMessage, type UserMessage, type BaseMessage } from '@sendbird/chat/message';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { type SerializedGroupChannel } from '../messaging.types';
import { useGetChannelName, useGetChannelMemberNickname, useMessagingConfig, getRelativeTimeLocaleKey } from '../utils';
import { getChannelsByUrl } from '../selectors';
import { setActiveChannelUrl, setShowMessageSearch, setTargetSearchMessage } from '../actions';
import { MemberAvatars } from './MemberAvatars';

type Props = {
    messages: BaseMessage[];
    showChannelName: boolean;
};

const classes = {
    list: clsx('w-full', 'list-none', 'p-0', 'mb-5'),
    listItem: clsx('w-full', 'mb-3'),
    messageButton: clsx(
        'w-full',
        'px-5',
        'py-3',
        'hover:bg-slate-grey-extra-light',
        'bg-transparent',
        'transition-all',
        'rounded-lg',
        'flex',
        'items-center',
        'justify-between',
        'cursor-pointer',
    ),
    avatar: clsx('w-[40px]', 'h-[40px]', 'mt-[0.15rem]', 'me-3', 'self-start', 'shrink-0'),
    messageInfo: clsx('block', 'flex-1'),
    senderAndDateWrapper: clsx('flex', 'items-center', 'justify-between'),
    sender: clsx('text-base', 'font-semibold', 'text-black'),
    message: clsx('block', 'text-sm', 'text-slate-grey', 'text-start'),
    date: clsx('text-xs', 'text-slate-grey'),
    channelName: clsx(
        'block',
        'w-full',
        'text-start',
        'text-xs',
        'text-black',
        'font-semibold',
        'line-clamp-1',
        'overflow-ellipsis',
    ),
};

export const MessageSearchResultsList = ({ messages, showChannelName }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const channelsByUrl = useSelector(getChannelsByUrl);
    const { userId } = useMessagingConfig();
    const getChannelName = useGetChannelName();
    const getChannelMemberNickname = useGetChannelMemberNickname();

    const handleMessageClick = useCallback(
        async (message: BaseMessage) => {
            const { channelUrl, messageId, createdAt } = message;
            dispatch(setShowMessageSearch(false));
            dispatch(setActiveChannelUrl(channelUrl));

            setTimeout(() => {
                // Navigates much more smoothly if we wait a sec here for channel to load and message refs to be set
                dispatch(setTargetSearchMessage({ messageId, createdAt }));
            }, 500);
        },
        [dispatch],
    );

    return (
        <ul className={classes.list}>
            {messages.map(message => {
                const channel = channelsByUrl[message.channelUrl] as SerializedGroupChannel;
                const channelName = getChannelName(channel, userId!) || t('messaging.no_members');
                const getChannelMember = (msg: FileMessage | UserMessage) =>
                    channel?.members.find(member => member.userId === msg.sender.userId);
                return (
                    (message.isUserMessage() || message.isFileMessage()) && (
                        <li key={message.messageId} className={classes.listItem}>
                            <button
                                type="button"
                                className={classes.messageButton}
                                onClick={() => handleMessageClick(message)}
                            >
                                <MemberAvatars
                                    members={[message.sender]}
                                    className={classes.avatar}
                                    showOnlineStatus={false}
                                />
                                <span className={classes.messageInfo}>
                                    <span className={classes.senderAndDateWrapper}>
                                        <span className={classes.sender}>
                                            {getChannelMember(message)
                                                ? getChannelMemberNickname(getChannelMember(message)!)
                                                : message.sender.nickname}
                                        </span>
                                        <span className={classes.date}>
                                            {t(`messaging.${getRelativeTimeLocaleKey(new Date(message.createdAt))}`, {
                                                formattedDate: moment(message.createdAt).format('M/D/YY'),
                                            })}
                                        </span>
                                    </span>
                                    {showChannelName && (
                                        <span className={classes.channelName}>
                                            {t('messaging.in_chat')}: {channelName}
                                        </span>
                                    )}
                                    <span className={classes.message}>
                                        {message.isUserMessage() ? message.message : message.name}
                                    </span>
                                </span>
                            </button>
                        </li>
                    )
                );
            })}
        </ul>
    );
};
