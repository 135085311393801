import { type Nullable } from '@Types';
import { type Cohort, pastEndDate } from 'Cohorts';
import {
    type ProgramInclusion,
    type UnlockedStreamsEntry,
    WillNotGraduateReasonCategory,
    ProgramInclusionStatus,
    RawCurriculumStatus,
    CurriculumStatus,
    AcademicProbationStatus,
} from './ProgramInclusion.types';

// See also will_not_graduate_reason.rb#BLACKLISTED_REASON_IDS
const violationOfTermsId = '6a063b3e-d9ac-411b-ad67-628499eb7a32';
const intentionallyMisrepresentedPriorEducationId = 'b0c9f928-2051-4b5f-bf2f-1468e526baac';
const academicSuspensionId = '16838360-d1eb-46aa-ad62-d3eb6558d63d';

export const blacklistedWillNotGraduateReasonIds = [
    violationOfTermsId,
    intentionallyMisrepresentedPriorEducationId,
    academicSuspensionId,
];

export const isIncluded = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.status === ProgramInclusionStatus.Included;

export const isCurrent = (programInclusion: Nullable<ProgramInclusion>) => !!programInclusion?.current;

export const hasGraduated = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.status === ProgramInclusionStatus.Graduated;

export const hasGraduatedWithHonors = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.status === ProgramInclusionStatus.Graduated && programInclusion?.graduatedWithHonors === true;

export const willNotGraduate = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.status === ProgramInclusionStatus.WillNotGraduate;

export const hasFailed = (programInclusion: Nullable<ProgramInclusion>) =>
    willNotGraduate(programInclusion) &&
    programInclusion?.willNotGraduateReason?.category === WillNotGraduateReasonCategory.failed;

export const graduatedOrWillNotGraduate = (programInclusion: Nullable<ProgramInclusion>) =>
    hasGraduated(programInclusion) || willNotGraduate(programInclusion);

export const hasBeenExpelled = (programInclusion: Nullable<ProgramInclusion>) =>
    willNotGraduate(programInclusion) && !hasFailed(programInclusion);

export const isCurrentOrHasCompletedProgram = (programInclusion: Nullable<ProgramInclusion>) =>
    isCurrent(programInclusion) || hasGraduated(programInclusion) || hasFailed(programInclusion);

export const isCurrentOrHasGraduatedProgram = (programInclusion: Nullable<ProgramInclusion>) =>
    isCurrent(programInclusion) || hasGraduated(programInclusion);

export const isIncludedButNotCurrent = (programInclusion: Nullable<ProgramInclusion>) =>
    isIncluded(programInclusion) && !isCurrent(programInclusion);

export const onFirstAcademicProbationNoHold = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.academicProbationStatus === AcademicProbationStatus.first_probation_no_hold;

export const onSecondAcademicProbationHold = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.academicProbationStatus === AcademicProbationStatus.second_probation_on_hold;

export const onAcademicProbation = (programInclusion: Nullable<ProgramInclusion>) =>
    onFirstAcademicProbationNoHold(programInclusion) || onSecondAcademicProbationHold(programInclusion);

export const onAdministrativeHold = (programInclusion: Nullable<ProgramInclusion>) =>
    programInclusion?.academicHold && !onAcademicProbation(programInclusion);

type UnlockedStreamsEntryLocalePackId = UnlockedStreamsEntry['localePackId'];

export const unlockedStreamsEntry = (
    programInclusion: ProgramInclusion,
    localePackId: UnlockedStreamsEntryLocalePackId,
) => programInclusion.unlockedStreams.find(entry => entry.localePackId === localePackId);

export const streamIsManuallyUnlocked = (
    programInclusion: Nullable<ProgramInclusion>,
    localePackId: UnlockedStreamsEntryLocalePackId,
) => {
    if (!programInclusion) return false;
    if (willNotGraduate(programInclusion)) return false;
    if (programInclusion.disableExamLocking) return true;

    const streamEntry = unlockedStreamsEntry(programInclusion, localePackId);
    return !!streamEntry && streamEntry.unlockedUntil > Date.now();
};

// FIXME: Ideally all this logic would be on the server and we wouldn't need this function, but we cannot
// add statuses on the server that are not supported by clients out in the wild. So, when we need a new
// status the process is:
// 1. add logic here to set it on new clients
// 2. wait until all clients have the logic to set the new status
// 3. move the logic to the server
// 4. move the client-side generation of the status from this function
export const getCurriculumStatus = (
    programInclusion: ProgramInclusion | null,
    cohort: Cohort | null,
): CurriculumStatus | null => {
    if (!programInclusion?.curriculumStatus || !cohort) return null;

    // Move generation of `WithdrawnOrExpelled` to the server once https://github.com/quanticedu/back_royal/pull/13565 is on all clients
    if (hasBeenExpelled(programInclusion)) {
        return CurriculumStatus.WithdrawnOrExpelled;
    }

    if (programInclusion.status === ProgramInclusionStatus.Included && pastEndDate(cohort)) {
        const notFinishedStatus: CurriculumStatus | null =
            (
                {
                    // Move generation of `PreGraduation` statuses to the server once
                    // https://github.com/quanticedu/back_royal/pull/12540 is on all clients
                    [RawCurriculumStatus.NotOnTrack]: CurriculumStatus.PreGraduationNotOnTrack,
                    [RawCurriculumStatus.AlmostThere]: CurriculumStatus.PreGraduationAlmostThere,

                    // The server is not sending these statuses down yet, but when we do the refactor above,
                    // we will need this here on old clients for backwards compatability
                    [RawCurriculumStatus.PreGraduationFinished]: CurriculumStatus.PreGraduationFinished,
                    [RawCurriculumStatus.PreGraduationNotOnTrack]: CurriculumStatus.PreGraduationNotOnTrack,
                    [RawCurriculumStatus.PreGraduationAlmostThere]: CurriculumStatus.PreGraduationAlmostThere,

                    // Move generation of `MissingGraduationRequirements` status to the server once
                    // https://github.com/quanticedu/back_royal/pull/13565 is on all clients
                    [RawCurriculumStatus.MissingGraduationRequirements]: CurriculumStatus.MissingGraduationRequirements,
                } as Record<Partial<RawCurriculumStatus>, Partial<CurriculumStatus>>
            )[programInclusion.curriculumStatus] || null;

        if (notFinishedStatus) return notFinishedStatus;

        if (
            // If `meetsGraduationRequirements` is true, we can be totally confident that the user has completed both their curriculum
            // (playlists / streams and projects) as well as the number of required specializations.
            programInclusion.meetsGraduationRequirements ||
            // If `meetsGraduationRequirements` is false, there's a chance that everything has been submitted, but is awaiting grading. In
            // this instance `curriculumStatus` will be "finished", indicating the user has completed all playlists/streams in the curriculum
            // and has at least submitted their required projects. However, `curriculumStatus` does not account for specializations since those
            // are technically outside of the curriculum.
            // So when `meetsGraduationRequirements` is false, we need to also check if the curriculum is complete and the number of required
            // specializations remaining is 0, in which case we should show `CurriculumStatus.PreGraduationFinished`.
            (programInclusion.curriculumStatus === RawCurriculumStatus.Finished &&
                programInclusion.numRequiredSpecializationsRemaining === 0)
        ) {
            return CurriculumStatus.PreGraduationFinished;
        }

        return CurriculumStatus.MissingGraduationRequirements;
    }

    // We really shouldn't be able to get into this block. We would be in the block above
    // in any case where the raw status was finished
    if (programInclusion.curriculumStatus === RawCurriculumStatus.Finished) {
        return CurriculumStatus.PreGraduationFinished;
    }

    // If we make it to here, then we can just return the raw status. All this code is just needed
    // to map from the one enum to the other.
    return {
        [RawCurriculumStatus.Failed]: CurriculumStatus.Failed,
        [RawCurriculumStatus.Honors]: CurriculumStatus.Honors,
        [RawCurriculumStatus.Graduated]: CurriculumStatus.Graduated,
        [RawCurriculumStatus.Week0]: CurriculumStatus.Week0,
        [RawCurriculumStatus.Week1]: CurriculumStatus.Week1,
        [RawCurriculumStatus.NotOnTrack]: CurriculumStatus.NotOnTrack,
        [RawCurriculumStatus.AlmostThere]: CurriculumStatus.AlmostThere,
        [RawCurriculumStatus.OnTrackFinished]: CurriculumStatus.OnTrackFinished,
        [RawCurriculumStatus.OnTrack]: CurriculumStatus.OnTrack,
        [RawCurriculumStatus.WithdrawnOrExpelled]: CurriculumStatus.WithdrawnOrExpelled,
        [RawCurriculumStatus.MissingGraduationRequirements]: CurriculumStatus.MissingGraduationRequirements,
        [RawCurriculumStatus.PreGraduationFinished]: CurriculumStatus.PreGraduationFinished,
        [RawCurriculumStatus.PreGraduationNotOnTrack]: CurriculumStatus.PreGraduationNotOnTrack,
        [RawCurriculumStatus.PreGraduationAlmostThere]: CurriculumStatus.PreGraduationAlmostThere,
    }[programInclusion.curriculumStatus];
};

// Normally, functions in here take a program inclusion as the first arg. This seemed like a reasonable
// place to make an exception
export const isPreGraduationStatus = (status: CurriculumStatus | null): boolean => {
    if (!status) return false;
    return [
        CurriculumStatus.PreGraduationFinished,
        CurriculumStatus.PreGraduationNotOnTrack,
        CurriculumStatus.PreGraduationAlmostThere,
    ].includes(status);
};

export const hasPreGraduationStatus = (programInclusion: ProgramInclusion | null, cohort: Cohort | null): boolean => {
    const status = getCurriculumStatus(programInclusion, cohort);
    return isPreGraduationStatus(status);
};
