import { careerProfilesApi } from 'CareerProfiles';
import { useSuspenseQuery } from 'ReduxHelpers';
import { useGetUserChannelMap } from './useGetUserChannelMap';
import { useGetUsersAndCareerProfiles } from './useGetUsersAndCareerProfiles';

export const useGetStudentsToMessage = () => {
    const [usersWithExistingMessages, userChannelMap] = useGetUserChannelMap();
    const { data: recommendedProfiles = [] } = useSuspenseQuery(careerProfilesApi, 'getRecommendedClassmates');
    const { data: relevantUserProfiles = [] } = useSuspenseQuery(
        careerProfilesApi,
        'getRelevantSendbirdUserCareerProfiles',
        usersWithExistingMessages,
    );
    const { availableUsers, suggestedUsers, profilesById } = useGetUsersAndCareerProfiles({
        recommendedProfiles,
        relevantUserProfiles,
        usersWithExistingMessages,
    });

    return { availableUsers, suggestedUsers, profilesById, userChannelMap };
};
