import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { memo, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { playlistEditorApi } from 'Playlists';
import { lessonsEditorApi, type Stream } from 'Lessons';
import { Box, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { playlistExamEvaluationsApi } from './PlaylistExamEvaluationsApi';
import getCoverageMaps from './getCoverageMaps';
import { ShowExamFrames } from './ShowExamFrames';
import { ShowAllStreamsCoverage } from './ShowAllStreamsCoverage';
import { type PlaylistExamEvaluation } from './PlaylistExamEvaluation.types';

const { useGetExamEvaluationQuery } = playlistExamEvaluationsApi;
const { useGetPlaylistQuery } = playlistEditorApi;
const { useGetStreamsQuery } = lessonsEditorApi;

const TAB_EXAM_SCREEN_BREAKDOWN = 'exam_screen_breakdown';
const TAB_EXAM_CONTENT_SOURCES = 'exam_content_sources';

type Props = {
    playlistExamEvaluationId: string;
};

function useData({ playlistExamEvaluationId }: { playlistExamEvaluationId: string }) {
    const {
        data: playlistExamEvaluationResponse,
        isLoading: playlistExamEvaluationIsLoading,
        error: getPlaylistExamEvaluationError,
    } = useGetExamEvaluationQuery(playlistExamEvaluationId);
    const playlistExamEvaluation = playlistExamEvaluationResponse?.contents.playlistExamEvaluations[0];

    const {
        data: playlistResponse,
        isLoading: playlistIsLoading,
        error: getPlaylistError,
    } = useGetPlaylistQuery(playlistExamEvaluation ? playlistExamEvaluation.playlistId : skipToken);
    const playlist = playlistResponse?.contents.playlists[0];

    const {
        data: streamsResponse,
        isLoading: streamsAreLoading,
        error: getStreamsError,
    } = useGetStreamsQuery(
        playlist
            ? {
                  filters: { locale_pack_id: playlist.streamEntries.map(entry => entry.localePackId) },
                  load_full_content_for_all_lessons: true,
              }
            : skipToken,
    );
    const sortedIds = playlist?.streamEntries.map(entry => entry.localePackId);
    const streams =
        sortedIds &&
        streamsResponse?.contents.lessonStreams
            .slice()
            .sort((s1, s2) => (sortedIds.indexOf(s1.localePack.id) > sortedIds.indexOf(s2.localePack.id) ? 1 : -1));

    const isLoading = playlistExamEvaluationIsLoading || playlistIsLoading || streamsAreLoading;
    const loadingError = getPlaylistExamEvaluationError || getPlaylistError || getStreamsError;
    if (loadingError) {
        throw loadingError;
    }

    return {
        playlistExamEvaluation,
        playlist,
        streams: streams as Stream<true>[], // Since we set load_full_content_for_all_lessons to true, we can reliably cast this to Stream<true>
        isLoading,
    };
}

const classes = {
    tabs: {
        container: 'mb-7',
        tab: 'me-3',
    },
    examTitle: clsx(['mb-4', 'text-2xl', 'font-bold']),
    examInfo: {
        heading: 'font-bold',
    },
};

function ExamAndPlaylistInfo({
    examStream,
    playlistExamEvaluation,
}: {
    examStream: Stream<true>;
    playlistExamEvaluation?: PlaylistExamEvaluation;
}) {
    const lastGeneratedAt =
        playlistExamEvaluation && formattedUserFacingDateTime(1000 * playlistExamEvaluation.lastGeneratedAt);

    return (
        <>
            <h1 className={classes.examTitle}>{examStream.title}</h1>
            <Box
                sx={{
                    border: '1px solid lightgray',
                    borderRadius: '8px',
                    padding: '16px',
                    display: 'inline-block',
                    margin: '0 0 30px',
                }}
            >
                <div data-testid="exam-and-playlist-info">
                    <p>
                        <strong className={classes.examInfo.heading}>Exam Tag:</strong> ({examStream.tag})
                    </p>
                    <p>
                        <strong className={classes.examInfo.heading}>Playlist:</strong> (
                        {playlistExamEvaluation?.playlistTag}) {playlistExamEvaluation?.playlistTitle}
                    </p>
                    <p>
                        <strong className={classes.examInfo.heading}>Exam Evaluation last updated:</strong>{' '}
                        {lastGeneratedAt}
                    </p>
                </div>
            </Box>
        </>
    );
}

function ShowPlaylistExamEvaluationComponent({ playlistExamEvaluationId }: Props) {
    const { playlistExamEvaluation, isLoading, streams } = useData({ playlistExamEvaluationId });
    const [currentTab, setCurrentTab] = useState<string>(TAB_EXAM_SCREEN_BREAKDOWN);
    const examStream = useMemo(() => streams?.find(stream => stream.exam), [streams]);

    const coverageMaps = playlistExamEvaluation && streams && getCoverageMaps(playlistExamEvaluation, streams);

    return (
        <>
            <Tabs
                className={classes.tabs.container}
                defaultValue={TAB_EXAM_SCREEN_BREAKDOWN}
                value={currentTab}
                onChange={(_event: React.SyntheticEvent, newValue: string) => {
                    setCurrentTab(newValue);
                }}
            >
                <Tab value={TAB_EXAM_SCREEN_BREAKDOWN} className={classes.tabs.tab} label="Exam Screen Breakdown" />
                <Tab value={TAB_EXAM_CONTENT_SOURCES} className={classes.tabs.tab} label="Exam Content Sources" />
            </Tabs>

            {examStream && (
                <ExamAndPlaylistInfo examStream={examStream} playlistExamEvaluation={playlistExamEvaluation} />
            )}

            {isLoading && <FrontRoyalSpinner />}
            {!isLoading && coverageMaps && (
                <>
                    {currentTab === TAB_EXAM_CONTENT_SOURCES && (
                        <ShowAllStreamsCoverage
                            streams={streams}
                            examStream={examStream!}
                            coverageMaps={coverageMaps}
                        />
                    )}

                    {currentTab === TAB_EXAM_SCREEN_BREAKDOWN && playlistExamEvaluation && (
                        <ShowExamFrames streams={streams} coverageMaps={coverageMaps} />
                    )}
                </>
            )}
        </>
    );
}

export const ShowPlaylistExamEvaluation = memo(
    ShowPlaylistExamEvaluationComponent,
) as typeof ShowPlaylistExamEvaluationComponent;

export default ShowPlaylistExamEvaluation;
