import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    type BaseMessage,
    MessageSearchOrder,
    type MessageSearchQuery,
    type MessageSearchQueryParams,
} from '@sendbird/chat/message';
import { useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { getActiveChannelUrl } from '../selectors';
import { setShowMessageSearch } from '../actions';
import { MessageSearchResultsList } from './MessageSearchResultsList';
import { Select } from './Select';

const classes = {
    container: clsx(
        'h-full',
        'w-full',
        'bg-white',
        'border-s',
        'border-solid',
        'border-slate-grey-mid-light',
        'ml-[-1px]',
    ),
    header: {
        container: clsx(
            'w-full',
            'h-[64px]',
            'ps-5',
            'pe-3',
            'flex',
            'items-center',
            'justify-between',
            'border-b',
            'border-solid',
            'border-slate-grey-mid-light',
        ),
        title: clsx('text-lg', 'font-semibold', 'text-black'),
        closeButton: clsx(
            'h-[40px]',
            'w-[40px]',
            'flex',
            'rounded-lg',
            'items-center',
            'justify-center',
            'text-black',
            'text-lg',
            'hover:bg-slate-grey-lightest',
        ),
    },
    main: {
        container: clsx(
            'w-full',
            'h-[calc(100%-64px)]',
            'max-h-full',
            'flex',
            'flex-col',
            'justify-start',
            'items-start',
            'overflow-hidden',
        ),
        form: {
            wrapper: clsx(
                'w-full',
                'min-h-[50px]',
                'grow-0',
                'shrink-0',

                'border-b',
                'border-solid',
                'border-slate-grey-mid-light',
                'px-[22px]',
                'py-[10px]',
                'bg-white',
            ),
            advancedFieldsWrapper: (showingAdvancedFields: boolean) =>
                clsx('flex', 'items-end', 'justify-start', 'flex-wrap', {
                    hidden: !showingAdvancedFields,
                }),
            mainFieldWrapper: clsx('w-full', 'flex', 'justify-start', 'items-start', 'flex-wrap'),
            mainField: clsx('grow', 'me-4', 'mb-[10px]'),
            input: clsx(
                'p-2',
                'border',
                'border-solid',
                'rounded-md',
                'border-slate-grey-mid-light',
                'text-black',
                'bg-white',
            ),
            mainFieldInput: clsx(
                'w-full',
                'min-w-[200px]',
                'p-2',
                'border',
                'border-solid',
                'rounded-md',
                'border-slate-grey-mid-light',
                'text-black',
            ),
            advancedToggle: clsx(
                'grow-0',
                'cursor-pointer',
                'flex',
                'mb-[10px]',
                'items-center',
                'justify-between',
                'self-center',
                'text-black',
            ),
            advancedToggleIcon: (showingAdvancedFields: boolean) =>
                clsx('text-black', 'text-[14px]', 'ms-[8px]', 'transition-all', {
                    'rotate-180': showingAdvancedFields,
                }),
            fieldItem: clsx('me-[15px]', 'flex', 'items-center', 'justify-start', 'mb-[10px]'),
            fieldItemLabel: clsx(
                'text-slate-grey',
                'font-semibold',
                'text-[16px]',
                'me-[10px]',
                'mt-2',
                'min-w-[3em]',
                'text-right',
                'inline-block',
            ),
            formActionsWrapper: clsx('flex', 'justify-start', 'items-center', 'ms-auto'),
            submitButton: clsx(
                'h-[40px]',
                'rounded-lg',
                'bg-slate-grey-extra-light',
                'text-black',
                'font-semibold',
                'text-[16px]',
                'px-[20px]',
                'flex',
                'justify-center',
                'items-center',
                'hover:bg-slate-grey-mid-light',
                'cursor-pointer',
                'disabled:opacity-40',
                'disabled:cursor-not-allowed',
                'me-[10px]',
            ),
            resetButton: clsx(
                'h-[40px]',
                'rounded-lg',
                'bg-slate-grey-extra-light',
                'text-black',
                'font-semibold',
                'text-[16px]',
                'px-[20px]',
                'flex',
                'justify-center',
                'items-center',
                'cursor-pointer',
                'hover:bg-slate-grey-mid-light',
                'disabled:opacity-40',
                'disabled:cursor-not-allowed',
            ),
        },
        results: {
            container: clsx('w-full', 'h-full', 'p-5', 'grow', 'shrink', 'overflow-auto'),
            noResults: clsx('text-[16px]', 'text-slate-grey', 'font-normal'),
            messagesList: clsx('w-full', 'mb-5'),
            loadMoreButton: clsx(
                'h-[40px]',
                'rounded-lg',
                'bg-slate-grey-extra-light',
                'text-black',
                'font-semibold',
                'text-[16px]',
                'px-[20px]',
                'flex',
                'justify-center',
                'items-center',
                'hover:bg-slate-grey-mid-light',
            ),
        },
    },
};

const formSchema = Yup.object().shape({
    keyword: Yup.string().required(),
    messageTimestampFrom: Yup.date().nullable(),
    messageTimestampTo: Yup.date().nullable(),
    ordering: Yup.string().oneOf(['score', 'time asc', 'time desc']).required(),
    searchChannels: Yup.string().oneOf(['all', 'active']).required(),
});

export const MessageSearch = () => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const dispatch = useDispatch();
    const [query, setQuery] = useState<MessageSearchQuery | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessages, setResultMessages] = useState<BaseMessage[]>([]);
    const [showingAdvancedFields, setShowingAdvancedFields] = useState(false);
    const [showingChannelName, setShowingChannelName] = useState(false);
    const activeChannelUrl = useSelector(getActiveChannelUrl);

    const uniqueResultMessages = useMemo(
        () => Object.values<BaseMessage>(resultMessages.reduce((obj, m) => ({ ...obj, [m.messageId]: m }), {})),
        [resultMessages],
    );

    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);

    const { getValues, reset, handleSubmit, formState, register, watch } = useForm({
        defaultValues: {
            keyword: '',
            messageTimestampFrom: null,
            messageTimestampTo: null,
            ordering: 'score',
            searchChannels: 'active',
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(formSchema),
    });

    const valid = !!watch('keyword')?.trim();
    const dirty = formState.isDirty;
    const searchChannels = watch('searchChannels');

    const submitHandler = useCallback(() => {
        if (!valid) return;

        setQuery(null);
        setResultMessages([]);

        const values = getValues();

        const buildQuery = () => {
            const baseParams: MessageSearchQueryParams = {
                // Remove special characters from the keyword; the search API does not support them
                keyword: values.keyword.replace(/[^\s\w]/g, ''),
                messageTimestampFrom: values.messageTimestampFrom ? new Date(values.messageTimestampFrom).getTime() : 0,
                messageTimestampTo: values.messageTimestampTo ? new Date(values.messageTimestampTo).getTime() : 0,
                order: values.ordering === 'score' ? MessageSearchOrder.SCORE : MessageSearchOrder.TIMESTAMP,
                reverse: values.ordering === 'time asc',
            };
            if (searchChannels === 'active') {
                baseParams.channelUrl = activeChannelUrl;
            }
            setQuery(sdk.createMessageSearchQuery(baseParams));
        };
        buildQuery();
        setShowingChannelName(values.searchChannels === 'all');
    }, [activeChannelUrl, getValues, sdk, searchChannels, valid]);

    const handleCloseClick = useCallback(() => {
        dispatch(setShowMessageSearch(false));
    }, [dispatch]);

    const handleResetClick = useCallback(() => {
        reset();
        setQuery(null);
        setResultMessages([]);
    }, [reset]);

    const handleLoadMoreClick = useCallback(async () => {
        if (!query) return;

        const queryMessages = (await query?.next()) ?? [];

        setResultMessages(curr => [...curr, ...queryMessages]);
    }, [query]);

    useEffect(() => {
        if (!query) return;
        if (resultMessages.length !== 0) return;

        const fetchMessages = () => {
            if (query && query.hasNext && !query.isLoading) {
                return query.next().then(messages => messages || []);
            }
            return Promise.resolve([]) as Promise<BaseMessage[]>;
        };

        setIsLoading(true);

        fetchMessages().then(messages => {
            setResultMessages(messages);
            setIsLoading(false);
        });
    }, [query, resultMessages.length]);

    const hasNext = query?.hasNext;
    const noResults = !isLoading && !!query && query.totalCount === 0;

    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.title}>
                    {t(`messaging.${searchChannels === 'all' ? 'search_all_chats' : 'search_this_chat'}`)}
                </h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className={classes.main.container}>
                <div className={classes.main.form.wrapper}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className={classes.main.form.mainFieldWrapper}>
                                <div className={classes.main.form.mainField}>
                                    <input
                                        {...register('keyword')}
                                        placeholder={`${t('messaging.search_placeholder')}`}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                submitHandler();
                                            }
                                        }}
                                        className={classes.main.form.mainFieldInput}
                                    />
                                </div>
                                <div className={classes.main.form.mainField}>
                                    <Select {...register('searchChannels')}>
                                        <option value="active">{t('messaging.this_chat')}</option>
                                        <option value="all">{t('messaging.all_chats')}</option>
                                    </Select>
                                </div>
                                <button
                                    type="button"
                                    className={classes.main.form.advancedToggle}
                                    onClick={() => setShowingAdvancedFields(!showingAdvancedFields)}
                                >
                                    <span>{t('messaging.more')}</span>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        className={classes.main.form.advancedToggleIcon(showingAdvancedFields)}
                                    />
                                </button>
                            </div>
                            <div className={classes.main.form.advancedFieldsWrapper(showingAdvancedFields)}>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="messageTimestampFrom">
                                        {t('messaging.from')}
                                    </label>
                                    <input
                                        {...register('messageTimestampFrom')}
                                        type="datetime-local"
                                        className={classes.main.form.input}
                                    />
                                </div>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="messageTimestampTo">
                                        {t('messaging.to')}
                                    </label>
                                    <input
                                        {...register('messageTimestampTo')}
                                        type="datetime-local"
                                        className={classes.main.form.input}
                                    />
                                </div>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="ordering">
                                        {t('messaging.order')}
                                    </label>
                                    <Select {...register('ordering')}>
                                        <option value="score">{t('messaging.most_relevant')}</option>
                                        <option value="time asc">{t('messaging.oldest_to_newest')}</option>
                                        <option value="time desc">{t('messaging.newest_to_oldest')}</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={classes.main.form.formActionsWrapper}>
                                <button
                                    className={classes.main.form.submitButton}
                                    type="submit"
                                    disabled={isLoading || !valid}
                                    onClick={submitHandler}
                                >
                                    {t('messaging.search')}
                                </button>
                                <button
                                    className={classes.main.form.resetButton}
                                    type="submit"
                                    onClick={handleResetClick}
                                    disabled={!dirty && !uniqueResultMessages.length}
                                >
                                    {t('messaging.reset')}
                                </button>
                            </div>
                        </form>
                    </LocalizationProvider>
                </div>
                <div className={classes.main.results.container}>
                    {!isLoading && noResults && (
                        <p className={classes.main.results.noResults}>{t('messaging.no_results')}</p>
                    )}
                    {!!uniqueResultMessages.length && (
                        <MessageSearchResultsList
                            messages={uniqueResultMessages}
                            showChannelName={showingChannelName}
                        />
                    )}
                    {isLoading && <FrontRoyalSpinner color="force-brand" className="no-delay" />}

                    {hasNext && !isLoading && uniqueResultMessages.length > 0 && (
                        <button
                            type="button"
                            className={classes.main.results.loadMoreButton}
                            onClick={handleLoadMoreClick}
                        >
                            {t('messaging.load_more')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
