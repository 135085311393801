import FrontRoyalSpinner from 'FrontRoyalSpinner';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendbirdSelectors } from '@sendbird/uikit-react';
import { useEffect, useState } from 'react';
import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { UserProfile } from './UserProfile';
import { GroupChannelInfo } from './GroupChannelInfo';
import { getActiveChannelUrl, getShowChannelInfo, getShowProfileUserId } from '../selectors';

const classes = {
    container: clsx('h-full'),
};

export const ChannelDetailsPanel = () => {
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const showProfileUserId = useSelector(getShowProfileUserId);
    const showChannelInfo = useSelector(getShowChannelInfo);

    const sbState = useSendbirdStateContext();
    const fetchChannel = sendbirdSelectors.getGetGroupChannel(sbState);
    const [channel, setChannel] = useState<GroupChannel | null>(null);

    useEffect(() => {
        if (activeChannelUrl) fetchChannel(activeChannelUrl).then(setChannel);
    }, [activeChannelUrl, fetchChannel]);

    if (!channel) return <FrontRoyalSpinner color="force-brand" />;

    return (
        <div className={classes.container}>
            {!!showProfileUserId && <UserProfile profileUserId={showProfileUserId} channel={channel} />}
            {showChannelInfo && !showProfileUserId && <GroupChannelInfo channel={channel} />}
        </div>
    );
};
