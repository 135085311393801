import { memo, useCallback } from 'react';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/pro-solid-svg-icons/faReply';
import { Button } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlingComponentsForSubComponents, getErrorBoundary } from 'FrontRoyalErrorBoundary';
import ListPlaylistExamEvaluations from './ListPlaylistExamEvaluations';
import ShowPlaylistExamEvaluation from './ShowPlaylistExamEvaluation';

type Props = {
    playlistExamEvaluationId: string | null;
};

function PlaylistExamEvaluationsComponent({ playlistExamEvaluationId }: Props) {
    const { loadRoute } = useNavigationHelper();

    const gotoIndex = useCallback(() => {
        loadRoute('/editor?section=exams');
    }, [loadRoute]);

    return (
        <>
            {playlistExamEvaluationId && (
                <Button className="my-5 flex items-center text-coral" onClick={() => gotoIndex()}>
                    <FontAwesomeIcon className="me-1" icon={faReply} />
                    <span className="">Back to Exams List</span>
                </Button>
            )}

            <div className="bg-white p-6">
                {!playlistExamEvaluationId && (
                    <ErrorBoundary FallbackComponent={getErrorBoundary(errorHandlingComponentsForSubComponents)}>
                        <ListPlaylistExamEvaluations />
                    </ErrorBoundary>
                )}
                {playlistExamEvaluationId && (
                    <ErrorBoundary FallbackComponent={getErrorBoundary(errorHandlingComponentsForSubComponents)}>
                        <ShowPlaylistExamEvaluation playlistExamEvaluationId={playlistExamEvaluationId} />
                    </ErrorBoundary>
                )}
            </div>
        </>
    );
}

export const PlaylistExamEvaluations = memo(
    PlaylistExamEvaluationsComponent,
) as typeof PlaylistExamEvaluationsComponent;

export default PlaylistExamEvaluations;
