/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { type Stream } from 'Lessons';
import { isEqual, startCase } from 'lodash/fp';
import { memo, type ReactNode, type FC, useState } from 'react';
import { twMerge } from 'Utils/customTwMerge';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlingComponentsForSubComponents, getErrorBoundary } from 'FrontRoyalErrorBoundary';
import { type ExamChallengeEntry } from './PlaylistExamEvaluation.types';
import { type FrameDescriptor } from './CoverageMaps.types';
import { FrameLinkListByLesson } from './FrameLinkListByLesson';
import { PlaylistExamEvaluationMarkdown } from './PlaylistExamEvaluationMarkdown';
import { playlistExamEvaluationsApi } from './PlaylistExamEvaluationsApi';
import { IgnorableWarningIcon } from './IgnorableWarningIcon';

const { useUpdateChallengeIgnoredValueMutation } = playlistExamEvaluationsApi;

const classes = {
    container: clsx(['w-[90%]', 'pb-4', 'ps-[42px]', 'pt-4']),
    calloutBox: {
        container: clsx(['mb-4', 'flex', 'flex-col', 'rounded-lg', 'border', 'p-3', 'leading-[1.1em]']),
    },
    warningBox: {
        container: clsx(['border-coral', 'bg-white']),
        header: {
            container: clsx(['mb-4', 'flex', 'justify-between', 'font-semibold', 'text-coral']),
            warningIcon: 'me-2',
            info: 'align-middle',
        },
        children: 'ps-[23px]',
        ignoreWarningForm: {
            container: 'text-gray-400',
            button: clsx(['align-middle', 'underline']),
        },
    },
    ignored: {
        container: 'border-gray-400',
        header: {
            container: 'text-gray-400',
        },
        ignoreWarningForm: {
            container: 'text-coral',
        },
    },
    unfair: {
        container: 'mb-8',
    },
    levelOfThinking: {
        header: {
            container: 'mb-4',
            label: 'font-bold',
        },
        explanation: {
            container: 'mb-5',
        },
        calloutBox: clsx(['border-beige-medium', 'bg-white', 'p-5', 'leading-[1.1em]']),
    },
    instructionalScreens: {
        container: 'mb-4',
        header: clsx(['mb-4', 'font-bold']),
        frameLinkList: 'ms-5',
    },
    noInstructionalScreens: {
        container: 'mt-2',
        possibleMatchesHeader: 'mb-4',
        frameLinkList: 'ms-5',
    },
};

function CalloutBox({ children, className }: { children: ReactNode; className?: string }) {
    return <div className={twMerge(classes.calloutBox.container, className)}>{children}</div>;
}

function WarningBoxHeader({
    headerText,
    ignored,
    playlistChallengeTutorBotRecordId,
}: {
    headerText: string;
    ignored: boolean;
    playlistChallengeTutorBotRecordId: string;
}) {
    return (
        <div className={clsx(classes.warningBox.header.container, ignored && classes.ignored.header.container)}>
            <div>
                <IgnorableWarningIcon ignored={ignored} className={classes.warningBox.header.warningIcon} />

                <span className={classes.warningBox.header.info}>
                    {headerText} {ignored ? '(Ignored)' : ''}
                </span>
            </div>
            <div>
                <ErrorBoundary FallbackComponent={getErrorBoundary(errorHandlingComponentsForSubComponents)}>
                    <IgnoreWarningForm
                        ignored={ignored}
                        playlistChallengeTutorBotRecordId={playlistChallengeTutorBotRecordId}
                    />
                </ErrorBoundary>
            </div>
        </div>
    );
}

function IgnoreWarningForm({
    ignored,
    playlistChallengeTutorBotRecordId,
}: {
    ignored: boolean;
    playlistChallengeTutorBotRecordId: string;
}) {
    const [updateChallengeIgnoredValue, { isLoading: isSubmitting, error: updateChallengeIgnoredValueError }] =
        useUpdateChallengeIgnoredValueMutation();

    if (updateChallengeIgnoredValueError) {
        throw updateChallengeIgnoredValueError;
    }

    // See isUpdating below. When the user triggers an update to the ignored value, `expectedIgnoredValue`
    // holds the new value, and allows us to track when the update is complete and the record has reloaded.
    const [expectedIgnoredValue, setExpectedIgnoredValue] = useState<boolean>(ignored);

    const sendUpdateChallengeIgnoredValue = (ignoredValue: boolean) => async () => {
        setExpectedIgnoredValue(ignoredValue);
        await updateChallengeIgnoredValue({ playlistChallengeTutorBotRecordId, ignored: ignoredValue });
    };

    // submitting the mutation above triggers two requests in serial: the mutation itself, which then invalidates
    // the whole playlist exam evaluation and triggers a refetch. We want the spinner to show until the refetch
    // is complete since that is when the UI updates to reflect the change.
    const isUpdating = isSubmitting || expectedIgnoredValue !== ignored;

    return (
        <div
            className={clsx(
                ignored ? classes.ignored.ignoreWarningForm.container : classes.warningBox.ignoreWarningForm.container,
            )}
        >
            {isUpdating ? (
                <FrontRoyalSpinner className="no-top-margin no-delay" />
            ) : (
                <div>
                    <IgnorableWarningIcon ignored={!ignored} className={classes.warningBox.header.warningIcon} />

                    <button
                        className={classes.warningBox.ignoreWarningForm.button}
                        type="button"
                        disabled={isUpdating}
                        onClick={sendUpdateChallengeIgnoredValue(!ignored)}
                    >
                        {ignored ? 'Restore' : 'Ignore'} Suggestion
                    </button>
                </div>
            )}
        </div>
    );
}

function WarningBox({
    children,
    headerText,
    ignored,
    className,
    playlistChallengeTutorBotRecordId,
}: {
    children: ReactNode;
    headerText: string;
    ignored: boolean;
    className?: string;
    playlistChallengeTutorBotRecordId: string;
}) {
    return (
        <CalloutBox className={clsx(classes.warningBox.container, className, ignored && classes.ignored.container)}>
            <WarningBoxHeader
                headerText={headerText}
                ignored={ignored}
                playlistChallengeTutorBotRecordId={playlistChallengeTutorBotRecordId}
            />
            <div className={classes.warningBox.children}>{children}</div>
        </CalloutBox>
    );
}

function Unfair({
    fairnessExplanation,
    frameDescriptors,
    ignored,
    playlistChallengeTutorBotRecordId,
}: {
    fairnessExplanation: string;
    frameDescriptors: FrameDescriptor[];
    ignored: boolean;
    playlistChallengeTutorBotRecordId: string;
}) {
    return (
        <WarningBox
            headerText="Suggestion"
            className={classes.unfair.container}
            ignored={ignored}
            playlistChallengeTutorBotRecordId={playlistChallengeTutorBotRecordId}
        >
            <PlaylistExamEvaluationMarkdown frameDescriptors={frameDescriptors}>
                {fairnessExplanation}
            </PlaylistExamEvaluationMarkdown>
        </WarningBox>
    );
}

function LevelOfThinking({
    levelOfThinking,
    levelOfThinkingExplanation,
    frameDescriptors,
    className,
}: {
    levelOfThinking: string;
    levelOfThinkingExplanation: string;
    className: string;
    frameDescriptors: FrameDescriptor[];
}) {
    return (
        <div className={className}>
            <div className={classes.levelOfThinking.header.container}>
                <span className={classes.levelOfThinking.header.label}>Level of Thinking: </span>
                {startCase(levelOfThinking)}
            </div>
            <CalloutBox className={classes.levelOfThinking.calloutBox}>
                <PlaylistExamEvaluationMarkdown frameDescriptors={frameDescriptors}>
                    {levelOfThinkingExplanation}
                </PlaylistExamEvaluationMarkdown>
            </CalloutBox>
        </div>
    );
}

type Props = {
    examChallengeEntry: ExamChallengeEntry;
    streams: Stream<true>[];
};

export const ExamChallengeBody: FC<Props> = memo(({ examChallengeEntry, streams }) => {
    const candidateInstructionalFrameDescriptors = streams
        .flatMap(s => s.lessons)
        .flatMap(lesson =>
            lesson.frames
                .filter(frame => examChallengeEntry.candidateInstructionalFrameIds.includes(frame.id))
                .map(frame => ({
                    lessonId: lesson.id,
                    frameId: frame.id,
                    frameIndex: lesson.frames.indexOf(frame),
                })),
        );

    return (
        <div className={classes.container}>
            {!examChallengeEntry.fair && examChallengeEntry.fairnessExplanation ? (
                <Unfair
                    fairnessExplanation={examChallengeEntry.fairnessExplanation}
                    frameDescriptors={candidateInstructionalFrameDescriptors}
                    ignored={examChallengeEntry.ignored}
                    playlistChallengeTutorBotRecordId={examChallengeEntry.id}
                />
            ) : null}

            {examChallengeEntry.levelOfThinking && examChallengeEntry.levelOfThinkingExplanation && (
                <LevelOfThinking
                    className={classes.levelOfThinking.explanation.container}
                    levelOfThinking={examChallengeEntry.levelOfThinking}
                    levelOfThinkingExplanation={examChallengeEntry.levelOfThinkingExplanation}
                    frameDescriptors={candidateInstructionalFrameDescriptors}
                />
            )}

            {examChallengeEntry.instructionalFrameIds.length > 0 && (
                <div className={classes.instructionalScreens.container}>
                    <p className={classes.instructionalScreens.header}>Instructional Screens:</p>
                    <div className={classes.instructionalScreens.frameLinkList}>
                        <FrameLinkListByLesson frameIds={examChallengeEntry.instructionalFrameIds} streams={streams} />
                    </div>
                </div>
            )}
            {examChallengeEntry.instructionalFrameIds.length === 0 && (
                <>
                    {examChallengeEntry.noInstructionalFramesExplanation && (
                        <WarningBox
                            headerText="No Instructional Screens Identified"
                            className={classes.noInstructionalScreens.container}
                            ignored={examChallengeEntry.ignored}
                            playlistChallengeTutorBotRecordId={examChallengeEntry.id}
                        >
                            <PlaylistExamEvaluationMarkdown frameDescriptors={candidateInstructionalFrameDescriptors}>
                                {examChallengeEntry.noInstructionalFramesExplanation}
                            </PlaylistExamEvaluationMarkdown>
                        </WarningBox>
                    )}
                    <div className={classes.noInstructionalScreens.container}>
                        <p className={classes.noInstructionalScreens.possibleMatchesHeader}>
                            The following instructional screens were considered as possible matches for this challenge:
                        </p>
                        <div className={classes.noInstructionalScreens.frameLinkList}>
                            <FrameLinkListByLesson
                                frameIds={examChallengeEntry.candidateInstructionalFrameIds}
                                streams={streams}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}, isEqual);
