import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { type MouseEvent, useCallback, useContext, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { type ContextItemPosition } from '../messaging.types';
import { calculateContextItemPosition } from '../utils/calculateContextItemPosition';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { setActiveChannelUrl } from '../actions';
import { getActiveChannelUrl } from '../selectors';
import { actions } from '../messagingSlice';
import { MessagingModal } from './MessagingModal';

const { setReplyMessageId } = actions;

type Props = {
    open: boolean;
    message: CoreMessageType;
    messageIsFromOtherUser: boolean;
    onClickButton: () => void;
    onClickMenuItem?: () => void;
};

const classes = {
    trigger: {
        wrapper: (open: boolean) =>
            clsx(
                'flex',
                'h-[40px]',
                'w-[40px]',
                'items-center',
                'justify-center',
                'rounded-lg',
                { 'hover:bg-slate-grey-lightest': !open },
                { 'bg-slate-grey-lightest': open },
            ),
        icon: clsx('text-[28px]', 'text-slate-grey'),
    },
    menu: {
        wrapper: (open: boolean) =>
            clsx(
                { hidden: !open },
                { block: open },
                'z-[100000000]',
                'bg-white',
                'shadow-lg',
                'absolute',
                'h-auto',
                'w-auto',
                'min-w-[150px]',
                'p-0',
            ),
        list: clsx('list-none', 'm-0', 'p-0'),
        listItem: clsx('m-0', 'p-0'),
        listItemButton: clsx(
            'w-full',
            'border-0',
            'bg-transparent',
            'py-3',
            'sm:py-1',
            'px-3',
            'text-left',
            'text-black',
            'hover:bg-slate-grey-lightest',
            'disabled:text-slate-grey',
            'disabled:hover:bg-transparent',
        ),
    },
    confirm: {
        wrapper: clsx(
            'bg-white',
            'p-5',
            'shadow-lg',
            'w-[500px]',
            'h-auto',
            'min-h-[200px]',
            'max-w-[95%]',
            'max-h-[95%]',
        ),
        header: {
            wrapper: clsx('flex', 'items-start', 'justify-between', 'mb-[60px]'),
            message: clsx('text-black', 'text-lg', 'font-semibold'),
            closeButton: clsx(
                'hover:bg-slate-grey-lightest',
                'flex',
                'justify-center',
                'items-center',
                'rounded-lg',
                'p-2',
                'cursor-pointer',
            ),
            closeIcon: clsx('text-black', 'text-lg'),
        },
        deleteError: clsx('text-red-600', 'text-sm', 'mb-2'),
        actions: {
            wrapper: clsx('flex', 'justify-end', 'gap-2'),
            button: (danger: boolean) =>
                clsx(
                    'px-5',
                    'py-3',
                    'flex',
                    'items-center',
                    'justify-center',
                    'rounded-lg',
                    'border',
                    'border-solid',
                    'border-slate-grey',
                    {
                        'bg-white hover:bg-slate-grey-lightest text-black': !danger,
                        'bg-[var(--sendbird-light-error-300)] hover:bg-[var(--sendbird-light-error-400)] text-white':
                            danger,
                    },
                ),
        },
    },
};

export const MessageContextMenu = ({
    open,
    message,
    messageIsFromOtherUser,
    onClickButton,
    onClickMenuItem,
}: Props) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const dispatch = useDispatch();
    const triggerRef = useRef<HTMLButtonElement>(null);
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const { currentChannel } = useGroupChannelContext();
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const channelWrapper = useContext(ChannelWrapperContext);
    const [position, setPosition] = useState<ContextItemPosition>({ height: 0, width: 0, top: 0, left: 0 });
    const [confirmingDelete, setConfirmingDelete] = useState(false);
    const hasReply = !!message.threadInfo?.replyCount;
    const hasParent = !!message.parentMessage;

    useClickAway(deleteModalRef, () => setConfirmingDelete(false));
    useClickAway(menuRef, () => {
        if (open) onClickButton();
    });

    const handleTriggerClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            const menuPosition = calculateContextItemPosition(e.currentTarget, channelWrapper?.current ?? undefined, {
                buffer: 10,
                maxWidth: 200,
            });
            setPosition(menuPosition);
            onClickButton();
        },
        [onClickButton, channelWrapper],
    );

    const handleReplyClick = useCallback(() => {
        dispatch(setReplyMessageId(message.messageId));
        dispatch(setActiveChannelUrl(currentChannel!.url));
        onClickMenuItem?.();
    }, [dispatch, message, currentChannel, onClickMenuItem]);

    const handleCopyClick = useCallback(() => {
        navigator.clipboard.writeText(message.message);
        onClickMenuItem?.();
    }, [message, onClickMenuItem]);

    const handleDeleteClick = useCallback(() => {
        setConfirmingDelete(true);
        onClickMenuItem?.();
    }, [onClickMenuItem]);

    const handleConfirmDelete = useCallback(async () => {
        if (!currentChannel) return;
        await currentChannel.deleteMessage(message);
        setConfirmingDelete(false);
    }, [currentChannel, message]);

    if (message.isAdminMessage()) return null;

    return (
        <>
            {/* Trigger Button */}
            <button
                type="button"
                ref={triggerRef}
                className={classes.trigger.wrapper(open)}
                onKeyDown={() => undefined}
                tabIndex={-1}
                onClick={handleTriggerClick}
            >
                <FontAwesomeIcon className={classes.trigger.icon} icon={faEllipsisV} />
            </button>

            {/* Context Menu */}
            {ReactDOM.createPortal(
                <div
                    className={classes.menu.wrapper(open)}
                    ref={menuRef}
                    style={{
                        top: 'top' in position ? `${position.top}px` : undefined,
                        right: 'right' in position ? `${position.right}px` : undefined,
                        bottom: 'bottom' in position ? `${position.bottom}px` : undefined,
                        left: 'left' in position ? `${position.left}px` : undefined,
                    }}
                >
                    <ul className={classes.menu.list}>
                        <li className={classes.menu.listItem}>
                            <button
                                className={classes.menu.listItemButton}
                                type="button"
                                onClick={handleReplyClick}
                                disabled={hasParent}
                            >
                                {t('messaging.reply')}
                            </button>
                        </li>
                        <li className={classes.menu.listItem}>
                            <button type="button" className={classes.menu.listItemButton} onClick={handleCopyClick}>
                                {t('messaging.copy')}
                            </button>
                        </li>
                        {!messageIsFromOtherUser && activeChannelUrl && (
                            <li className={classes.menu.listItem}>
                                <button
                                    type="button"
                                    className={classes.menu.listItemButton}
                                    disabled={hasReply}
                                    onClick={handleDeleteClick}
                                >
                                    {t('messaging.delete')}
                                </button>
                            </li>
                        )}
                    </ul>
                </div>,
                document.getElementsByTagName('body')[0],
            )}

            {/* Confirm Delete Modal */}
            <MessagingModal open={confirmingDelete}>
                <div ref={deleteModalRef} className={classes.confirm.wrapper}>
                    <div className={classes.confirm.header.wrapper}>
                        <p className={classes.confirm.header.message}>{t('messaging.confirm_delete_this_message')}</p>
                        <button
                            className={classes.confirm.header.closeButton}
                            type="button"
                            onClick={() => setConfirmingDelete(false)}
                        >
                            <FontAwesomeIcon className={classes.confirm.header.closeIcon} icon={faTimes} />
                        </button>
                    </div>
                    <div className={classes.confirm.actions.wrapper}>
                        <button
                            className={classes.confirm.actions.button(false)}
                            type="button"
                            onClick={() => setConfirmingDelete(false)}
                        >
                            {t('messaging.cancel')}
                        </button>
                        <button
                            className={classes.confirm.actions.button(true)}
                            type="button"
                            onClick={handleConfirmDelete}
                        >
                            {t('messaging.delete_message')}
                        </button>
                    </div>
                </div>
            </MessagingModal>
        </>
    );
};
