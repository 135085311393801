/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type User } from '@sendbird/chat';
import clsx from 'clsx';
import { ImageWithFallback } from 'ImageWithFallback';
import { type MouseEvent, type KeyboardEvent, type FC, useState, useRef, useCallback } from 'react';
import { useClickAway } from 'react-use';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';

const pillClasses = {
    container: (selected: boolean) =>
        clsx(
            'flex',
            'my-[5px]',
            'me-2',
            'min-h-[27px]',
            'rounded-[12.5px]',
            'pr-[10px]',
            'border',
            'transition-colors',
            'border-transparent',
            'hover:border-slate-grey',
            'focus:outline-none',
            selected ? 'bg-slate-grey border-slate-grey' : 'bg-slate-grey-extra-light',
        ),
    avatar: clsx('h-[25px]', 'w-[25px]', 'me-[5px]', 'self-center', 'rounded-full', 'pointer-events-none'),
    name: (selected: boolean) =>
        clsx(
            'font-semibold',
            'text-[16px]',
            'pointer-events-none',
            'break-anywhere',
            selected ? 'text-white' : 'text-black',
        ),
    icon: (selected: boolean) =>
        clsx(
            'text-[16px]',
            selected ? 'text-slate-grey-mid' : 'text-slate-grey',
            'ms-[5px]',
            'hidden',
            'sm:block',
            'flex-shrink-0',
        ),
};

export const SelectedUserPill: FC<{ user: User; handleListItemClick: (user: User) => void }> = ({
    handleListItemClick,
    user,
}) => {
    const [selected, setSelected] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => {
        if (selected) {
            setSelected(false);
        }
    });

    const onClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setSelected(v => !v);
        ref.current?.focus();
    }, []);

    const onKeyDown = useCallback(
        (e: KeyboardEvent, u: User) => {
            if (selected && (e.key === 'Delete' || e.key === 'Backspace')) {
                handleListItemClick(u);
            }
        },
        [handleListItemClick, selected],
    );

    return (
        <div
            ref={ref}
            tabIndex={0}
            className={pillClasses.container(selected)}
            onClick={onClick}
            onKeyDown={e => onKeyDown(e, user)}
        >
            <ImageWithFallback
                className={pillClasses.avatar}
                src={user.profileUrl}
                alt={user.nickname}
                fallbackSrc={defaultAvatarSrc}
            />
            <div className={pillClasses.name(selected)}>{user.nickname}</div>
            <button
                type="button"
                className={pillClasses.icon(selected)}
                onClick={e => {
                    e.stopPropagation();
                    handleListItemClick(user);
                }}
            >
                <FontAwesomeIcon icon={faXmark} className="pointer-events-none" />
            </button>
        </div>
    );
};
