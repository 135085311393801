// city, state, and country are optional and were intended to serve as a fallback for
// the User model, which has manually entered city, state, and country fields from the
// mailing address UI.
function locationString(placeDetails, city, state, country) {
    const locationStringParts = [];
    const cityStateNamesToExclude = ['Singapore'];
    const usaAndCityStateCountryCodesToExclude = ['US', 'USA', 'SG', 'HK', 'GI', 'VA'];

    const _placeCity = placeCity(placeDetails);
    const _placeState = placeState(placeDetails);
    const _placeCountry = placeCountry(placeDetails);
    const _placeCountryLong = placeCountryLong(placeDetails);

    if (_placeCity || _placeState) {
        // it's possible that there's no city, but there is a state
        // An example is Tokyo, JP
        if (_placeCity) {
            locationStringParts.push(_placeCity);
        }

        // Note: Some locations like 'Hong Kong' will not have a placeState
        // Note: Some city-states may be marked by Google as having the same name as their state; filter out
        if (_placeState && !cityStateNamesToExclude.includes(_placeState) && _placeState !== _placeCity) {
            locationStringParts.push(_placeState);
        }

        // Only show the country code if not in the US and not a city-state
        if (_placeCountry && !usaAndCityStateCountryCodesToExclude.includes(_placeCountry)) {
            locationStringParts.push(_placeCountry);
        }
    } else if (city && state) {
        locationStringParts.push(city);

        if (!cityStateNamesToExclude.includes(state)) {
            locationStringParts.push(state);
        }

        // Only show the country code if not in the US and not in a city-state
        if (country && !usaAndCityStateCountryCodesToExclude.includes(country)) {
            locationStringParts.push(country);
        }
    } else if (_placeCountry) {
        locationStringParts.push(_placeCountryLong);
    }

    return locationStringParts.join(', ');
}

function formattedAddress(placeDetails) {
    return placeDetails ? placeDetails.formatted_address : '';
}

function placeCity(placeDetails) {
    if (placeDetails) {
        // If converting key case, the transform fn mishandles underscore + number
        const adminArea = placeDetails.administrative_area_level_3 || placeDetails.administrative_area_level3;
        // See bottom of https://developers.google.com/places/web-service/supported_types
        return (
            (placeDetails.locality && placeDetails.locality.long) ||
            (adminArea && adminArea.long) ||
            (placeDetails.colloquial_area && placeDetails.colloquial_area.long)
        );
    }
    return null;
}

function placeState(placeDetails) {
    if (placeDetails) {
        // If converting key case, the transform fn mishandles underscore + number
        const adminArea = placeDetails.administrative_area_level_1 || placeDetails.administrative_area_level1;
        // We use 'short' to prefer the abbreviation, though in international addresses this is sometimes the same as the 'long'
        return adminArea && adminArea.short;
    }
    return null;
}

function placeCountry(placeDetails) {
    // We use 'short' since we prefer the country code
    return placeDetails?.country?.short ?? null;
}

function placeCountryLong(placeDetails) {
    return placeDetails?.country?.long ?? null;
}

export default {
    locationString,
    formattedAddress,
    placeCity,
    placeState,
    placeCountry,
    placeCountryLong,
};
