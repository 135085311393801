import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type SerializedChannelMember } from '../messaging.types';

export const useGetChannelMemberNickname = () => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });

    const getChannelMemberNickname = useCallback(
        (member: SerializedChannelMember, opts: { short: boolean } = { short: false }) => {
            const { short } = opts;
            const { nickname, isActive } = member;
            const deactivated = isActive ? '' : ` (${t('messaging.deactivated')})`;

            if (short) return `${nickname.split(/\s+/g)[0]}${deactivated}`;

            return `${nickname}${deactivated}`;
        },
        [t],
    );

    return getChannelMemberNickname;
};
