import { useState, type FC, type ReactNode } from 'react';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { type User } from '@sendbird/chat';
import { useMessagingConfig } from '../utils/useMessagingConfig';

export const MessagingProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { appId, accessToken, userId, colorSet } = useMessagingConfig();
    const [connectedUser, setConnectedUser] = useState<User>();

    if (!userId || !accessToken || !appId) return null;

    return (
        <SendBirdProvider
            appId={appId}
            userId={userId}
            accessToken={accessToken}
            colorSet={colorSet}
            breakpoint="767px"
            sdkInitParams={{ localCacheEnabled: true }}
            eventHandlers={{
                connection: {
                    onConnected: user => {
                        setConnectedUser(user);
                    },
                },
            }}
        >
            {connectedUser?.userId === userId && children}
        </SendBirdProvider>
    );
};
