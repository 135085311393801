// A simple factory fn to return a "promiseCache" that will cache promises
// based on a passed key and will clear them from the cache when they resolve
// This is useful for dealing with promises in React.Suspense
export const promiseCacheFactory = <T>() => {
    const cache = new Map();

    const updateCacheAndReturnCachedPromise = (key: string, promise: Promise<T>, delayMs = 15) => {
        // cache the promise if it doesn't exist in the cache yet
        if (!cache.has(key)) {
            const wrappedPromise = promise
                .then(result => {
                    // wait a beat before removing the promise from the cache on the chance
                    // that an immediate render happen between state being updated and the promise resolving
                    setTimeout(() => cache.delete(key), delayMs);

                    return result;
                })
                .catch(error => {
                    cache.delete(key);
                    throw error;
                });

            cache.set(key, wrappedPromise);
        }

        return cache.get(key) as Promise<T>;
    };

    const resetCache = () => cache.clear();

    return [updateCacheAndReturnCachedPromise, resetCache] as const;
};
