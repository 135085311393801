import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setActiveChannelUrl, setShowChannelInfo, setShowNewMessage, setShowProfileUserId } from '../actions';

const classes = {
    container: clsx(
        'h-[64px]',
        'flex',
        'items-center',
        'justify-between',
        'ps-5',
        'pe-3',
        'bg-white',
        'border-b',
        'border-solid',
        'border-slate-grey-mid-light',
    ),
    heading: clsx('text-lg', 'font-semibold', 'text-black'),
    newMessageButton: {
        button: clsx(
            'hover:bg-slate-grey-lightest',
            'flex',
            'justify-center',
            'items-center',
            'rounded-lg',
            'p-2',
            '-mt-[3px]',
        ),
        icon: clsx('text-black', 'text-lg'),
    },
};

export const ChannelListHeader = () => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const dispatch = useDispatch();

    const handleNewMessageClick = useCallback(() => {
        dispatch(setShowNewMessage(true));
        dispatch(setActiveChannelUrl(''));
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
    }, [dispatch]);

    return (
        <div className={classes.container}>
            <h3 className={classes.heading}>{t('messaging.chats')}</h3>
            <button type="button" className={classes.newMessageButton.button} onClick={handleNewMessageClick}>
                <FontAwesomeIcon icon={faPenToSquare} className={classes.newMessageButton.icon} />
            </button>
        </div>
    );
};
