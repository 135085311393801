import { type FileMessage } from '@sendbird/chat/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { isImageMessage, isVideoMessage } from '../utils';
import { MessagingModal } from './MessagingModal';

type Props = {
    onCancel: () => void;
    message: FileMessage;
    open: boolean;
};

export const FileViewer = ({ onCancel, message, open }: Props) => {
    if (!message) return null;

    return (
        <MessagingModal open={open}>
            <div className="h-full w-full rounded-[8px] bg-white">
                <div className="header flex h-[64px] items-center justify-start border-b border-solid border-slate-grey-mid-light py-1 pe-3 ps-5">
                    <h3 className="me-2 line-clamp-2 overflow-ellipsis text-sm font-semibold text-black sm:line-clamp-1 sm:text-lg">
                        {message.name}
                    </h3>
                    <div className="text-[16px] text-slate-grey">{message.sender.nickname}</div>
                    <div className="ms-auto flex shrink-0 items-center justify-end">
                        <a
                            href={message.url}
                            download={message.name}
                            className="-mt-[3px] me-1 ms-auto inline-flex items-center justify-center rounded-lg p-2 hover:bg-slate-grey-lightest"
                        >
                            <FontAwesomeIcon icon={faDownload} className="text-lg text-black" />
                        </a>
                        <button
                            type="button"
                            className="-mt-[3px] flex items-center justify-center rounded-lg p-2 hover:bg-slate-grey-lightest"
                            onClick={onCancel}
                        >
                            <FontAwesomeIcon icon={faTimes} className="text-lg text-black" />
                        </button>
                    </div>
                </div>
                <div className="flex h-[calc(100%-64px)] items-center justify-center overflow-y-auto p-5">
                    {isImageMessage(message) && (
                        <img src={message.url} alt={message.name} className="max-h-full max-w-full object-contain" />
                    )}
                    {isVideoMessage(message) && (
                        <video src={message.url} controls className="max-h-full max-w-full">
                            <track kind="captions" />
                        </video>
                    )}
                </div>
            </div>
        </MessagingModal>
    );
};
