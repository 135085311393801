import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = () => document.getElementById('sendbird-modal-root') as HTMLDivElement;

export const MessagingModal = ({ children, open }: { open: boolean; children: ReactNode }) => {
    const element = (
        <div className="absolute left-0 top-0 z-[10000] flex h-full w-full items-center justify-center rounded-[8px]">
            {children}
        </div>
    );

    if (!open) return null;

    return createPortal(element, modalRoot());
};
