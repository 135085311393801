import { type RenderCustomSeparatorProps } from '@sendbird/uikit-react/types/types';
import clsx from 'clsx';
import { formattedUserFacingMonthDayYearLong } from 'DateHelpers';

const classes = {
    container: clsx('relative', 'left-[-24px]', 'flex', 'w-[calc(100%+48px)]', 'items-center', 'py-2'),
    line: clsx('inline-block', 'h-[1px]', 'w-full', 'border-none', 'bg-slate-grey-mid-light'),
    dateWrapper: clsx('mx-[6.5px]', 'my-0', 'flex', 'whitespace-nowrap'),
    date: clsx('text-xxs', 'font-semibold', 'text-slate-grey'),
};

export const DateSeparator = ({ message }: RenderCustomSeparatorProps) => (
    <div className={classes.container}>
        <div className={classes.line} />
        <div className={classes.dateWrapper}>
            <span className={classes.date}>{formattedUserFacingMonthDayYearLong(new Date(message.createdAt))}</span>
        </div>
        <div className={classes.line} />
    </div>
);
