import { memo } from 'react';
import { useAddChannelUsersUpdatedListener } from '../utils';

// This component exists to give a common place to add arbitrary channel
// event listeners to. It should be rendered directly inside of the MessagingProvider
export const ChannelListeners = memo(() => {
    useAddChannelUsersUpdatedListener();

    return null;
});
