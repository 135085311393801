import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import clsx from 'clsx';
import { type ChangeEvent, useCallback, type FC } from 'react';
import { CameraResultType, CameraSource, getPhoto } from 'MobileFileUpload';

const classes = {
    web: {
        label: (disabled: boolean) =>
            clsx(
                'm-0',
                'block',
                'border-none',
                'bg-transparent',
                'p-0',
                'text-black',
                'transition-opacity',
                'hover:brightness-90',
                disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer',
            ),
        input: clsx('hidden'),
        icon: clsx('text-md', 'rtl:-scale-x-100'),
    },
};

type SendFileMessage = (file: File | Blob) => void;
type Props = { disabled: boolean; sendFileMessage: SendFileMessage };

const WebFilePicker: FC<Props> = ({ disabled, sendFileMessage }) => {
    const onChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            const file = e?.target?.files?.[0];
            if (!file) return;

            sendFileMessage(file);
        },
        [sendFileMessage],
    );

    return (
        <label htmlFor="attach-chat-file" className={classes.web.label(disabled)}>
            <input
                id="attach-chat-file"
                type="file"
                className={classes.web.input}
                onChange={onChange}
                disabled={disabled}
                onClick={event => {
                    event.currentTarget.value = '';
                }}
            />
            <FontAwesomeIcon className={classes.web.icon} icon={faPaperclip} />
        </label>
    );
};

const MobileFilePicker: FC<Props> = ({ disabled, sendFileMessage }) => {
    const onClick = useCallback(async () => {
        const { webPath } = await getPhoto(CameraSource.Prompt, CameraResultType.Uri);
        if (!webPath) return;

        const response = await fetch(webPath);
        const blob = await response.blob();

        sendFileMessage(blob);
    }, [sendFileMessage]);

    return (
        <button onClick={onClick} type="button" disabled={disabled}>
            <FontAwesomeIcon className={classes.web.icon} icon={faCamera} />
        </button>
    );
};

export const FilePicker: FC<Props> = props =>
    window.CORDOVA ? <MobileFilePicker {...props} /> : <WebFilePicker {...props} />;
