/* eslint-disable react/jsx-wrap-multilines */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandableCardList, ExpandableCardListContext } from 'ExpandableContainer';
import {
    type Lesson,
    type Stream,
    type Frame,
    type ChallengesTutorBotDescription,
    type ChallengeDescription,
    frameEditorLink,
} from 'Lessons';
import { isEqual, startCase } from 'lodash/fp';
import { memo, useContext, useMemo, useState, type FC } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { type CoverageMaps } from './CoverageMaps.types';
import { ExamChallengeCard } from './ExamChallengeCard';
import { type ExamChallengeEntry, type ExamFrameEntry } from './PlaylistExamEvaluation.types';
import { WarningIcon } from './WarningIcon';
import { IgnoredWarningIcon } from './IgnoredWarningIcon';
import { IgnorableWarningIcon } from './IgnorableWarningIcon';

type Props = {
    streams: Stream<true>[];
    coverageMaps: CoverageMaps;
};

const challengeShouldHaveWarning = (challenge: ExamChallengeEntry) => !challenge.fair;
const challengeHasUnignoredWarning = (challenge: ExamChallengeEntry) =>
    challengeShouldHaveWarning(challenge) && !challenge.ignored;

const LEVELS_OF_THINKING = {
    // These keys are in the order that they should be displayed in the pie chart
    recall: { labelColor: '#FFA726' },
    follow_procedure: { labelColor: '#C91B63' },
    apply_concepts: { labelColor: '#00A3A0' },
    demonstrate_judgement: { labelColor: '#2E96FF' },
    interpret_data: { labelColor: '#B800D8' },
    synthesize_concepts: { labelColor: '#2731C8' },
    unknown: { labelColor: '#EF5350' },
};

const cardClasses = {
    header: {
        container: clsx('flex w-full'),
        iconContainer: clsx(['flex', 'shrink-0', 'grow-0', 'basis-5', 'justify-center', 'self-start']),
        warningIcon: clsx(['-ms-3', 'mt-2', 'text-lg']),
        descriptionContainer: clsx(['mx-2', 'flex', 'grow', 'flex-col', 'justify-start']),
        screen: {
            container: clsx(['mb-1', 'font-bold']),
            screenNumber: 'text-md',
            frameLink: clsx(['ml-2', 'inline-block', 'text-xxs', 'text-blue']),
            tutorBotDescription: {
                container: clsx(['pe-3', 'leading-[1.1rem]']),
                mainText: (open: boolean) => clsx(!open && 'line-clamp-1 overflow-ellipsis'),
            },
        },
    },
    body: {
        container: 'p-5',
        noChallengesMessage: clsx(['m-2', 'p-2', 'italic', 'text-gray']),
    },
    cardClassName: clsx(['py-2', 'px-2', 'mb-4']),
    buttonClassName: clsx(['items-start', 'mt-3']),
};

function Header({
    frame,
    lesson,
    frameIndex,
    examFrameEntry,
}: {
    lesson: Lesson<true>;
    frame: Frame;
    frameIndex: number;
    examFrameEntry: ExamFrameEntry;
}) {
    const hasChallengeWithWarning = examFrameEntry.challenges?.find(challengeShouldHaveWarning);
    const allWarningsIgnored = examFrameEntry.challenges?.every(
        challenge => !challengeShouldHaveWarning(challenge) || challenge.ignored,
    );
    const { open } = useContext(ExpandableCardListContext);

    return (
        <div className={cardClasses.header.container}>
            <div className={cardClasses.header.iconContainer}>
                {hasChallengeWithWarning ? (
                    <IgnorableWarningIcon ignored={allWarningsIgnored} className={cardClasses.header.warningIcon} />
                ) : null}
            </div>
            <div className={cardClasses.header.descriptionContainer}>
                <div className={cardClasses.header.screen.container}>
                    <span className={cardClasses.header.screen.screenNumber}>Screen {frameIndex + 1}</span>
                    <a
                        href={frameEditorLink({ lessonId: lesson.id, frameId: frame.id })}
                        target="_blank"
                        rel="noreferrer"
                        className={cardClasses.header.screen.frameLink}
                        onClick={event => {
                            event.stopPropagation(); // Prevents the event from bubbling up and triggering the accordion to open or close
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </a>
                </div>
                <div className={cardClasses.header.screen.tutorBotDescription.container}>
                    <span className={cardClasses.header.screen.tutorBotDescription.mainText(open)}>
                        {frame.tutorBotDescription.mainText}
                    </span>
                </div>
            </div>
        </div>
    );
}

function Body({
    frame,
    examFrameEntry,
    streams,
}: {
    frame: Frame;
    examFrameEntry: ExamFrameEntry;
    streams: Stream<true>[];
}) {
    const challenges = (frame.tutorBotDescription as ChallengesTutorBotDescription)
        .challenges as ChallengeDescription[];
    const challengeDescriptionsById: Record<string, ChallengeDescription> = challenges
        ? challenges.reduce((acc: Record<string, ChallengeDescription>, challengeDescription: ChallengeDescription) => {
              acc[challengeDescription.id] = challengeDescription;
              return acc;
          }, {})
        : {};

    return (
        <div className={cardClasses.body.container}>
            {!examFrameEntry.challenges?.length && (
                <p className={cardClasses.body.noChallengesMessage}>No challenges included on this slide.</p>
            )}
            {examFrameEntry.challenges.map((examChallengeEntry, challengeIndex) => {
                const challengeDescription = challengeDescriptionsById[examChallengeEntry.challengeId];
                if (!challengeDescription) {
                    throw new Error('No challenge description found for challenge');
                }
                return (
                    <ExamChallengeCard
                        key={examChallengeEntry.challengeId}
                        challengeIndex={challengeIndex}
                        isSoleChallengeOnFrame={examFrameEntry.challenges.length === 1}
                        challengeDescription={challengeDescription}
                        examChallengeEntry={examChallengeEntry}
                        streams={streams}
                    />
                );
            })}
        </div>
    );
}

function ExamFrame({
    frame,
    coverageMaps,
    lesson,
    frameIndex,
    streams,
}: {
    frame: Frame;
    coverageMaps: CoverageMaps;
    lesson: Lesson<true>;
    frameIndex: number;
    streams: Stream<true>[];
}) {
    const examFrameEntry = coverageMaps.examFrameEntries.find(entry => entry.frameId === frame.id);
    if (!examFrameEntry) throw new Error('No exam frame entry found for frame');

    return (
        <ExpandableCardList
            header={<Header frame={frame} lesson={lesson} frameIndex={frameIndex} examFrameEntry={examFrameEntry} />}
            body={<Body frame={frame} examFrameEntry={examFrameEntry} streams={streams} />}
            cardClassName={cardClasses.cardClassName}
            buttonClassName={cardClasses.buttonClassName}
        />
    );
}

const classes = {
    frameListHeader: clsx(['frame-list-header', 'mb-[15px]', 'p-3']),
    levelOfThinking: {
        container: 'mb-8',
        header: clsx(['mb-2', 'text-md', 'font-semibold']),
    },
    showAllScreens: {
        label: clsx(['ms-2', 'text-[11px]', 'text-gray-400']),
        warningIcon: '-mt-[3px]',
    },
    frameCounts: {
        container: 'my-2',
        warningIcon: 'me-2',
        info: clsx(['inline-block', 'align-middle']),
    },
    framesWithWarnings: 'text-coral',
    framesWithOnlyIgnoredWarnings: 'text-gray-400',
    lesson: {
        container: 'mb-8',
        title: clsx(['mb-4', 'text-lg', 'font-bold']),
    },
};

export const ShowExamFrames: FC<Props> = memo(({ streams, coverageMaps }) => {
    const [showAllScreens, setShowAllScreens] = useState(false);
    const examStream: Stream<true> | undefined = useMemo(() => streams.find(stream => stream.exam), [streams]);
    const displayedFrames = useMemo(() => {
        const shouldShowFrame = (frame: Frame) =>
            showAllScreens ||
            coverageMaps.examFrameEntries.some(
                entry => entry.frameId === frame.id && entry.challenges?.some(challengeHasUnignoredWarning),
            );

        return examStream?.lessons.flatMap(lesson => lesson.frames.filter(shouldShowFrame)) ?? [];
        // we intentionally leave the coverageMaps out of the dependencies because we only want to recompute this
        // when the showAllScreens state changes.  This leaves the list of displayedFrames static even if you mark
        // suggestions as ignored in such a way that would otherwise cause the frame to be hidden -- until you
        // navigate away or toggle the showAllScreens state.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examStream, showAllScreens]);

    const allFrames = useMemo(() => examStream?.lessons.flatMap(lesson => lesson.frames) ?? [], [examStream]);
    const framesWithWarnings = useMemo(
        () =>
            allFrames.filter(frame => {
                const relatedEntry = coverageMaps.examFrameEntries.find(entry => entry.frameId === frame.id);
                return relatedEntry?.challenges?.some(challengeHasUnignoredWarning);
            }),
        [allFrames, coverageMaps],
    );
    const framesWithOnlyIgnoredWarnings = useMemo(
        () =>
            allFrames.filter(frame => {
                const relatedEntry = coverageMaps.examFrameEntries.find(entry => entry.frameId === frame.id);
                return (
                    relatedEntry?.challenges?.some(challengeShouldHaveWarning) &&
                    !relatedEntry?.challenges?.some(challengeHasUnignoredWarning)
                );
            }),
        [allFrames, coverageMaps],
    );

    const allChallenges = useMemo(
        () => coverageMaps.examFrameEntries.flatMap(entry => entry.challenges ?? []),
        [coverageMaps],
    );

    const challengeLevelOfThinkingBreakdown = useMemo(
        () =>
            allChallenges.reduce((acc, challenge) => {
                const label = challenge.levelOfThinking ?? 'unknown';
                if (!acc[label]) {
                    acc[label] = 0;
                }
                acc[label] += 1;
                return acc;
            }, {} as Record<string, number>),
        [allChallenges],
    );

    const levelOfThinkingData = useMemo(
        () =>
            // Ensure consistent ordering of levels of thinking labels
            Object.keys(LEVELS_OF_THINKING).map(levelOfThinking => {
                const count = challengeLevelOfThinkingBreakdown[levelOfThinking] ?? 0;
                const percentage = (count / allChallenges.length) * 100;
                const percentageLabel = percentage < 1 && percentage > 0 ? '<1' : Math.floor(percentage);
                return {
                    id: levelOfThinking,
                    label: `${startCase(levelOfThinking)} ${percentageLabel}%`,
                    value: count,
                    color: LEVELS_OF_THINKING[levelOfThinking as keyof typeof LEVELS_OF_THINKING].labelColor,
                };
            }),
        [challengeLevelOfThinkingBreakdown, allChallenges.length],
    );

    return (
        <>
            <div className={classes.frameListHeader}>
                <div className={classes.levelOfThinking.container}>
                    <h2 className={classes.levelOfThinking.header}>
                        Level of Thinking Across {allChallenges.length} Challenges
                    </h2>
                    <PieChart
                        series={[
                            {
                                arcLabel: item => `${Math.floor((item.value / allChallenges.length) * 100)}%`,
                                arcLabelMinAngle: 30,
                                data: levelOfThinkingData,
                                cx: 70,
                                outerRadius: 70,
                            },
                        ]}
                        width={700}
                        height={150}
                    />
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showAllScreens}
                                    onChange={event => setShowAllScreens(event.target.checked)}
                                />
                            }
                            label={
                                <span>
                                    Show all screens{' '}
                                    {!showAllScreens && (
                                        <span className={classes.showAllScreens.label}>
                                            Only showing screens with{' '}
                                            <WarningIcon className={classes.showAllScreens.warningIcon} />
                                        </span>
                                    )}
                                </span>
                            }
                        />
                    </FormGroup>
                    {framesWithWarnings.length ? (
                        <div className={classes.frameCounts.container}>
                            <WarningIcon className={classes.frameCounts.warningIcon} />
                            <p className={clsx([classes.frameCounts.info, classes.framesWithWarnings])}>
                                {framesWithWarnings.length} out of {allFrames.length} screens contain suggestions for
                                improvement
                            </p>
                        </div>
                    ) : (
                        <p>No suggestions for improvement found in any screens!</p>
                    )}
                    {framesWithOnlyIgnoredWarnings.length ? (
                        <div className={classes.frameCounts.container}>
                            <IgnoredWarningIcon className={classes.frameCounts.warningIcon} />
                            <p className={clsx([classes.frameCounts.info, classes.framesWithOnlyIgnoredWarnings])}>
                                {framesWithOnlyIgnoredWarnings.length} screens with all suggestions ignored
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>
            {examStream?.lessons.map(lesson => (
                <div key={lesson.id} className={classes.lesson.container}>
                    {/* Only show the lesson title if there is more than one lesson (usually there should be only 1) */}
                    {examStream.lessons.length > 1 && (
                        <div className={classes.lesson.title}>Lesson: {lesson.title}</div>
                    )}
                    {displayedFrames.map((frame: Frame) => (
                        <ExamFrame
                            key={frame.id}
                            frame={frame}
                            coverageMaps={coverageMaps}
                            lesson={lesson}
                            streams={streams}
                            frameIndex={lesson.frames.indexOf(frame)}
                        />
                    ))}
                </div>
            ))}
        </>
    );
}, isEqual);
