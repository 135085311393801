import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';
import sbSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import clsx from 'clsx';
import { memo, useEffect, useMemo, useState } from 'react';

const classes = {
    container: (count: number) =>
        clsx(
            'absolute',
            'pointer-events-none',
            'h-[19px]',
            count > 99 ? 'w-[26px]' : 'w-[19px]',
            'rounded-full',
            'border',
            'border-white',
            'overflow-hidden',
            'sm:top-[calc(50%-23px)]',
            'right-[79px]',
            'rtl:sm:right-auto',
            'rtl:sm:left-[79px]',
            'top-[calc(50%-18px)]',
            'sm:right-[82px]',
            'z-[110]',
        ),
    content: clsx(
        'pointer-events-none',
        'text-center',
        'text-xxs',
        'font-semibold',
        'bg-blue',
        'text-white',
        'h-full',
        'w-full',
    ),
};

export const UnreadCount = memo(() => {
    const [unreadCount, setUnreadCount] = useState(0);
    const sbContext = useSendbirdStateContext();

    const sdk = sbSelectors.getSdk(sbContext);

    const messageChangeHandler = useMemo(
        () =>
            new UserEventHandler({
                onTotalUnreadMessageCountChanged: count => {
                    setUnreadCount(count.groupChannelCount);
                },
            }),
        [],
    );

    useEffect(() => {
        // Get initial count at mount
        sdk.groupChannel.getTotalUnreadMessageCount().then(v => {
            setUnreadCount(v);
        });
    }, [sdk]);

    useEffect(() => {
        // update count as new messages are received
        sdk.addUserEventHandler('message_count', messageChangeHandler);

        return () => {
            sdk.removeUserEventHandler('message_count');
        };
    }, [messageChangeHandler, sdk]);

    return (
        unreadCount > 0 && (
            <div className={classes.container(unreadCount)}>
                <div className={classes.content}>{unreadCount > 99 ? '99+' : unreadCount}</div>
            </div>
        )
    );
});
