import {
    type GroupChannelMessageListProps,
    MessageList as BaseMessageList,
} from '@sendbird/uikit-react/GroupChannel/components/MessageList';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useState, useEffect, useMemo } from 'react';

export const MessageList = (props: GroupChannelMessageListProps) => {
    const { scrollToBottom, setIsScrollBottomReached, scrollToMessage, currentChannel, messages } =
        useGroupChannelContext();
    const [scrollChannel, setScrollChannel] = useState('');

    useEffect(() => {
        // set the scrollChannel every time currentChannel changes
        if (currentChannel) setScrollChannel(currentChannel.url);
    }, [currentChannel]);

    const messageToScrollTo = useMemo(() => {
        if (!currentChannel) return null;
        // wait for the messages channel to match the currentChannel
        // because they can update at different times when changing channels
        // and we need to wait until the messages are correct to trigger the scroll once
        if (scrollChannel !== messages[0]?.channelUrl) return null;

        const { lastMessage, myLastRead } = currentChannel;

        if (myLastRead <= (lastMessage?.createdAt || 0)) return messages.find(m => m.createdAt >= myLastRead) || null;

        return lastMessage;
    }, [currentChannel, messages, scrollChannel]);

    useEffect(() => {
        if (!messageToScrollTo) return;

        // scroll to messageToScrollTo unless it is the last message
        if (messageToScrollTo.messageId !== currentChannel!.lastMessage?.messageId) {
            scrollToMessage(messageToScrollTo.createdAt, messageToScrollTo.messageId);
        } else {
            scrollToBottom();
            setTimeout(() => {
                setIsScrollBottomReached(true);
            }, 50);
        }
        // once we scroll on this channel initially, reset the scrollChannel to make
        // sure we don't interfere with the default scroll behavior
        setScrollChannel('');
    }, [currentChannel, messageToScrollTo, scrollToBottom, scrollToMessage, setIsScrollBottomReached]);

    return <BaseMessageList {...props} />;
};
