import { memo, type ReactNode, useCallback, useMemo, useRef, type FC, useState, useEffect } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import Oreo from 'Oreo';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { type Column, useTable, useSortBy, useGlobalFilter, usePagination, type Row } from 'react-table';
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { playlistExamEvaluationsApi } from './PlaylistExamEvaluationsApi';
import { type PlaylistExamEvaluationIndexSummary } from './PlaylistExamEvaluation.types';

const { useGetExamEvaluationsQuery } = playlistExamEvaluationsApi;

// eslint-disable-next-line max-lines-per-function
const ListPlaylistExamEvaluationsComponent: FC = () => {
    const { data, isLoading, error } = useGetExamEvaluationsQuery();

    if (error) {
        throw error;
    }

    const { loadRoute } = useNavigationHelper();
    const [quickFilterValue, setQuickFilterValue] = useState('');

    const playlistExamEvaluations = useMemo(
        () =>
            data?.contents?.playlistExamEvaluations &&
            [...data.contents.playlistExamEvaluations].sort((a, b) => (a.playlistTitle > b.playlistTitle ? 1 : -1)),
        [data],
    );

    const gotoPlaylistExamEvaluation = useCallback(
        (playlistExamEvaluationId: string) => {
            loadRoute(`/editor?section=exams&id=${playlistExamEvaluationId}`);
        },
        [loadRoute],
    );

    const columns = useRef<Column<PlaylistExamEvaluationIndexSummary>[]>([
        {
            Header: 'Exam Tag',
            accessor: 'examStreamTag',
        },
        {
            Header: 'Exam Title',
            accessor: 'examStreamTitle',
        },
        {
            Header: 'Exam Published',
            accessor: (row: PlaylistExamEvaluationIndexSummary) =>
                formattedUserFacingDateTime(row.examStreamPublishedAt * 1000),
            sortType: (rowA: Row<PlaylistExamEvaluationIndexSummary>, rowB: Row<PlaylistExamEvaluationIndexSummary>) =>
                rowA.original.examStreamPublishedAt > rowB.original.examStreamPublishedAt ? 1 : -1,
        },
        {
            Header: 'Playlist Tag',
            accessor: 'playlistTag',
        },
        {
            Header: 'Playlist Title',
            accessor: 'playlistTitle',
        },
        {
            Header: 'Cohorts',
            accessor: (row: PlaylistExamEvaluationIndexSummary) => row.cohortNames.join(', '),
        },
        {
            Header: 'Exam Eval Modified',
            accessor: (row: PlaylistExamEvaluationIndexSummary) =>
                formattedUserFacingDateTime(row.lastGeneratedAt * 1000),
            sortType: (rowA: Row<PlaylistExamEvaluationIndexSummary>, rowB: Row<PlaylistExamEvaluationIndexSummary>) =>
                rowA.original.lastGeneratedAt > rowB.original.lastGeneratedAt ? 1 : -1,
        },
    ]).current;

    const {
        headers = [],
        prepareRow = () => undefined,
        page = [],
        rows,
        setGlobalFilter,
        setPageSize,
        gotoPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            initialState: { pageSize: 10, pageIndex: 0, sortBy: [], globalFilter: '' },
            data: playlistExamEvaluations ?? [],
            autoResetSortBy: false,
            autoResetGlobalFilter: false,
            autoResetPage: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        setGlobalFilter(quickFilterValue);
        if (quickFilterValue.length) {
            gotoPage(0);
        }
    }, [quickFilterValue, setGlobalFilter, gotoPage]);

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={headers.length}
            count={quickFilterValue.length ? rows.length : playlistExamEvaluations?.length ?? 0}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={(_event, newPage: number) => gotoPage(newPage)}
            onRowsPerPageChange={event => {
                setPageSize(parseInt(event.target.value, 10));
                gotoPage(0);
            }}
            ActionsComponent={TablePaginationActions}
        />
    );

    return (
        <>
            <h1 className="mb-8 text-2xl font-bold">Exam Evaluation</h1>
            {isLoading && <FrontRoyalSpinner className="no-top-margin no-delay static-height" />}
            {!isLoading && playlistExamEvaluations && (
                <>
                    <div className="flex w-full justify-start">
                        <TextField
                            name="quickFilter"
                            value={quickFilterValue}
                            onChange={e => setQuickFilterValue(e.target.value)}
                            label="Quick Filter"
                            variant="standard"
                            sx={{
                                width: '300px',
                                marginBottom: '-30px',
                                zIndex: 1,
                                '& input': { border: 'none !important' },
                            }}
                        />
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>{pagination}</TableRow>
                            <TableRow>
                                {headers.map(column => (
                                    <TableCell
                                        {...column.getHeaderProps()}
                                        component="th"
                                        sortDirection={
                                            column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                                        }
                                    >
                                        <TableSortLabel
                                            active={column.isSorted}
                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            onClick={() => column.toggleSortBy()}
                                        >
                                            {column.render('Header') as ReactNode}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <TableRow
                                        key={row.original.id as string}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: `${Oreo.COLOR_V3_BEIGE_LIGHT}`,
                                            },
                                        }}
                                        onClick={() => gotoPlaylistExamEvaluation(row.original.id as string)}
                                    >
                                        {row.cells.map(cell => {
                                            const { key, role } = cell.getCellProps();
                                            return (
                                                <TableCell key={key} role={role}>
                                                    {cell.render('Cell') as ReactNode}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>{pagination}</TableRow>
                        </TableFooter>
                    </Table>
                </>
            )}
        </>
    );
};

export const ListPlaylistExamEvaluations = memo(
    ListPlaylistExamEvaluationsComponent,
) as typeof ListPlaylistExamEvaluationsComponent;

export default ListPlaylistExamEvaluations;
