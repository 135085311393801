import React, { useCallback, useRef, useState, type ReactNode } from 'react';
import ReactDOM from 'react-dom';
import ReactEmojiPicker from 'emoji-picker-react';
import { useClickAway } from 'react-use';
import { type ContextItemPosition } from '../messaging.types';
import { calculateContextItemPosition } from './calculateContextItemPosition';

type Props = {
    // Whether the emoji picker is open. The position should be set via the
    // setter exported from the hook before setting `open` to `true`.
    open: boolean;
    // The emoji key is the unified code (includes skintone) for the emoji.
    // Example: '1f44b-1f3fe' is the waving hand with medium-dark skin tone.
    onEmojiSelection: (emojiKey: string) => void;
    // Whether to show the expandable reactions shortlist on open.
    // Otherwise, shows the full, expanded emoji menu.
    showReactions?: boolean;
    // A callback that can be invoked when clicking outside the emoji container
    onClickOutside?: (e: Event) => void;
};

/**
 * A hook to open and position an emoji picker
 * and to handle emoji selection
 */
export const useEmojiPicker = ({ open, onEmojiSelection, showReactions = false, onClickOutside }: Props) => {
    const [position, setPosition] = useState<ContextItemPosition>({ height: 0, width: 0, top: 0, left: 0 });
    const wrapperRef = useRef<HTMLDivElement>(null);

    useClickAway(wrapperRef, e => {
        onClickOutside?.(e);
    });

    const setPositionFromElement = useCallback(
        (
            triggerElement: Element,
            // The container used to determine the boundaries of where to position the picker.
            // Defaults to the window.
            containerElement?: Element,
        ) => {
            setPosition(
                calculateContextItemPosition(triggerElement, containerElement, {
                    buffer: 10,
                    maxWidth: 300,
                }),
            );
        },
        [setPosition],
    );

    const EmojiPicker = ReactDOM.createPortal(
        <div data-testid="emoji-picker-wrapper" ref={wrapperRef}>
            <ReactEmojiPicker
                open={open}
                reactionsDefaultOpen={showReactions}
                onReactionClick={reactionClick => onEmojiSelection(reactionClick.unified)}
                onEmojiClick={emojiClick => onEmojiSelection(emojiClick.unified)}
                previewConfig={{ showPreview: false }}
                allowExpandReactions
                autoFocusSearch={false}
                // Some of the library styling is overridden in Messaging.scss
                className="messaging-emoji-picker"
                style={{
                    maxHeight: `${position.height}px`,
                    maxWidth: `${position.width}px`,
                    position: 'absolute',
                    zIndex: 100000000,
                    top: 'top' in position ? `${position.top}px` : undefined,
                    right: 'right' in position ? `${position.right}px` : undefined,
                    bottom: 'bottom' in position ? `${position.bottom}px` : undefined,
                    left: 'left' in position ? `${position.left}px` : undefined,
                }}
            />
        </div>,
        document.getElementsByTagName('body')[0],
    ) as ReactNode;

    return { EmojiPicker, setPositionFromElement };
};
