/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { type Lesson, type Stream } from 'Lessons';
import { isEqual } from 'lodash/fp';
import { ExpandableCardList } from 'ExpandableContainer';
import { memo, type FC } from 'react';
import { type CoverageMaps } from './CoverageMaps.types';
import { ShowLearningObjectiveCoverage } from './ShowLearningObjectiveCoverage';

type Props = {
    lesson: Lesson<true>;
    stream: Stream<true>;
    coverageMaps: CoverageMaps;
};

const classes = {
    title: {
        primary: clsx(['inline-block', 'ms-2.5', 'text-xs', 'min-[365px]:text-base']),
    },
    body: 'm-4',
    learningObjective: clsx('pb-6'),
    card: clsx(['p-2', 'mb-4', 'bg-gray-50']),
};

export const ShowLessonCoverage: FC<Props> = memo(({ lesson, coverageMaps, stream }) => {
    const entry = coverageMaps.lesson[lesson.id];

    const coveragePercent = entry.coveragePercentage == null ? null : Math.round(100 * entry.coveragePercentage);
    const coveragePercentText = coveragePercent == null ? '' : `(${coveragePercent}% coverage)`;

    const learningObjectives = stream.streamLessonTutorBotRecords.find(r => r.lessonId === lesson.id)?.outline
        ?.learningObjectives;

    return (
        <ExpandableCardList
            header={
                <div className={clsx(classes.title.primary)}>
                    <span className="font-semibold">Lesson {coveragePercentText}:</span> <span>{lesson.title}</span>
                </div>
            }
            body={
                <div className={classes.body}>
                    {learningObjectives &&
                        learningObjectives.map(learningObjective => (
                            <ShowLearningObjectiveCoverage
                                key={learningObjective.id}
                                coverageMaps={coverageMaps}
                                learningObjective={learningObjective}
                            />
                        ))}
                </div>
            }
            cardClassName={classes.card}
        />
    );
}, isEqual);
