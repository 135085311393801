import { type CameraPermissionType, CameraSource, Camera, CameraResultType } from '@capacitor/camera';
import i18n from 'ReactI18N';

export const getCameraSupported = () => !!window.CORDOVA?.capabilities?.camera;

export const cameraSources = [CameraSource.Camera, CameraSource.Photos, CameraSource.Prompt] as const;

// Camera.requestPermissions will only prompt the user for permissions once.
// Followup attempts will just resolve with the system-level setting.
export const requestPermissions = async (source: CameraSource) => {
    // 'CAMERA' | 'PHOTOS' -> 'camera' | 'photos'
    const lowercaseSource = source.toLowerCase() as CameraPermissionType;
    const permissionStatus = await Camera.requestPermissions({ permissions: [lowercaseSource] });
    return permissionStatus[lowercaseSource];
};

export const getPhoto = async (source: CameraSource, resultType = CameraResultType.DataUrl) =>
    Camera.getPhoto({
        correctOrientation: true,
        height: 512,
        quality: 70,
        resultType,
        source,
        width: 512,
        promptLabelCancel: i18n.t('mobileFileUpload.mobileFileUpload.cancel')!,
        promptLabelHeader: i18n.t('mobileFileUpload.mobileFileUpload.photo')!,
        promptLabelPhoto: i18n.t('mobileFileUpload.mobileFileUpload.fromPhotos')!,
        promptLabelPicture: i18n.t('mobileFileUpload.mobileFileUpload.takePicture')!,
    });

export { Camera, CameraSource, CameraResultType };
