import { useCallback } from 'react';
import { isSerializedOpenChannel } from './typeUtils';
import { useGetChannelMemberNickname } from './useGetChannelMemberNickname';
import { type SerializedChannel } from '../messaging.types';

const DEFAULT_GROUP_CHANNEL_NAME = 'Group Channel';

export const useGetChannelName = () => {
    const getChannelMemberNickname = useGetChannelMemberNickname();

    const getChannelName = useCallback(
        (channel: SerializedChannel, userId: string) => {
            if (!channel) return '';

            if (isSerializedOpenChannel(channel)) return channel.name;

            if (channel.name !== DEFAULT_GROUP_CHANNEL_NAME) return channel.name;

            const members = channel.members;
            const otherMembers = members.filter(m => m.userId !== userId);

            if (otherMembers.length > 1)
                return otherMembers.map(member => getChannelMemberNickname(member, { short: true })).join(', ');

            if (otherMembers.length === 1) return getChannelMemberNickname(otherMembers[0]);

            return '';
        },
        [getChannelMemberNickname],
    );

    return getChannelName;
};
