import { type FC, memo } from 'react';
import { isEqual } from 'lodash/fp';
import { type Lesson, frameEditorLink, type Stream, streamEditorPath } from 'Lessons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

type Props = {
    lesson?: Lesson<true>;
    frameEntries: { frameId: string; lessonId: string; frameIndex: number }[];
    stream?: Stream<true>;
};

const classes = {
    container: 'mb-2',
    course: {
        container: 'mb-1',
        label: 'font-semibold',
        title: 'me-1',
        editLink: clsx(['-mt-[2px]', 'inline-block', 'text-[10px]', 'text-blue']),
    },
    lesson: {
        container: 'mb-1',
        label: 'font-semibold',
    },
    screens: {
        label: 'font-semibold',
        editLink: clsx(['-mt-[2px]', 'me-1', 'inline-block', 'text-[10px]', 'text-blue']),
    },
};

export const FrameLinkList: FC<Props> = memo(({ lesson, stream, frameEntries }) => {
    if (frameEntries.length === 0) {
        return null;
    }
    return (
        <div className={classes.container}>
            {stream && (
                <div className={classes.course.container}>
                    <span className={classes.course.label}>Course:</span>{' '}
                    <span className={classes.course.title}>{stream.title}</span>
                    <a
                        href={streamEditorPath({ streamId: stream.id })}
                        target="_blank"
                        rel="noreferrer"
                        title="Edit Course"
                        className={classes.course.editLink}
                    >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </a>
                </div>
            )}
            {lesson && (
                <div className={classes.lesson.container}>
                    <span className={classes.lesson.label}>Lesson:</span> {lesson.title} ({lesson.tag})
                </div>
            )}

            <div data-testid="screens">
                <span className={classes.screens.label}>Screen{frameEntries.length > 1 ? 's' : ''}:</span>{' '}
                {frameEntries.map((frameEntry, index) => {
                    const { frameId, lessonId, frameIndex } = frameEntry;
                    return (
                        <span key={frameId}>
                            {frameIndex + 1}{' '}
                            <a
                                className={classes.screens.editLink}
                                href={frameEditorLink({ frameId, lessonId })}
                                target="_blank"
                                rel="noreferrer"
                                title="Edit Frame"
                            >
                                <FontAwesomeIcon className="" icon={faArrowUpRightFromSquare} />
                            </a>
                            {frameEntries.length > 2 && index < frameEntries.length - 2 && ', '}
                            {frameEntries.length > 2 && index === frameEntries.length - 2 && ', and '}
                            {frameEntries.length === 2 && index === 0 && ' and '}
                        </span>
                    );
                })}
            </div>
        </div>
    );
}, isEqual);
