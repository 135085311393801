import ConnectionHandler from '@sendbird/uikit-react/handlers/ConnectionHandler';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sbSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import NetworkConnection from 'NetworkConnection';
import { memo, useEffect, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenChat } from '../actions';

export const HandleDisconnection = memo(
    ({ setConnected, connected }: { setConnected: Dispatch<SetStateAction<boolean>>; connected: boolean }) => {
        const sbContext = useSendbirdStateContext();
        const sdk = sbSelectors.getSdk(sbContext);
        const dispatch = useDispatch();

        const disconnectedHandler = useMemo(
            () =>
                new ConnectionHandler({
                    onDisconnected: () => {
                        dispatch(setOpenChat(false));
                        setConnected(false);
                    },
                    onReconnectSucceeded: () => {
                        setConnected(true);
                    },
                }),
            [dispatch, setConnected],
        );

        useEffect(() => {
            sdk.addConnectionHandler('disconnectedHandler', disconnectedHandler);

            return () => {
                sdk.removeConnectionHandler('disconnectedHandler');
            };
        }, [disconnectedHandler, sdk]);

        useEffect(() => {
            const id = setInterval(() => {
                if (NetworkConnection.offline && connected) {
                    dispatch(setOpenChat(false));
                    setConnected(false);
                }

                if (NetworkConnection.online && !connected) {
                    setConnected(true);
                }
            }, 5000);

            return () => {
                clearInterval(id);
            };
        }, [connected, dispatch, setConnected]);

        return null;
    },
);
