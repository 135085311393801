import { UserOnlineState, type User } from '@sendbird/chat';
import { useSelector } from 'react-redux';
import { sendBirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { useCallback } from 'react';
import { type MaybeNullish } from '@Types';
import { getSerializedUsersToMessage } from '../selectors';

export const useGetConnectionStatus = () => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);

    const usersToMessage = useSelector(getSerializedUsersToMessage);

    const getConnectionStatus = useCallback(
        (user: MaybeNullish<User>) => {
            if (!user) return UserOnlineState.OFFLINE;

            if (!usersToMessage) return user.connectionStatus;
            // Ideally we always want to check against the user we have stored in redux
            // for consistency across the app and because those users are polled for and kept up to date
            // via usePollForUsers
            const matchedUser = usersToMessage.availableUsers.find(u => u.userId === user.userId);

            if (!matchedUser) return user.connectionStatus;

            return sdk.buildUserFromSerializedData(matchedUser).connectionStatus;
        },
        [sdk, usersToMessage],
    );

    return getConnectionStatus;
};
