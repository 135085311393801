/* eslint-disable react/jsx-wrap-multilines */
import clsx from 'clsx';
import { isEqual } from 'lodash/fp';
import { ExpandableCardList } from 'ExpandableContainer';
import { memo, type FC } from 'react';
import { type LearningObjective } from 'StreamLessonTutorBotRecord';
import { type CoverageMaps } from './CoverageMaps.types';
import { ShowCoreConceptCoverage } from './ShowCoreConceptCoverage';

type Props = {
    learningObjective: LearningObjective;
    coverageMaps: CoverageMaps;
};

const classes = {
    title: {
        primary: clsx(['inline-block', 'ms-2.5', 'text-xs', 'min-[365px]:text-base', 'leading-[1.1rem]', 'w-[95%]']),
    },
    body: 'm-4',
    card: clsx(['p-2', 'bg-white', 'mb-4']),
};

export const ShowLearningObjectiveCoverage: FC<Props> = memo(({ coverageMaps, learningObjective }) => {
    const entry = coverageMaps.learningObjective[learningObjective.id];

    const coveragePercent = entry.coveragePercentage == null ? null : Math.round(100 * entry.coveragePercentage);
    const coveragePercentText = coveragePercent == null ? '' : `(${coveragePercent}% coverage)`;

    return (
        <ExpandableCardList
            header={
                <div className={clsx(classes.title.primary)}>
                    <strong className="font-semibold">Learning Objective: {coveragePercentText}</strong>{' '}
                    {learningObjective.title}
                </div>
            }
            body={
                <div className={classes.body}>
                    {learningObjective.coreConcepts.map(coreConcept => (
                        <ShowCoreConceptCoverage
                            key={coreConcept.id}
                            coreConcept={coreConcept}
                            coverageMaps={coverageMaps}
                        />
                    ))}
                </div>
            }
            cardClassName={classes.card}
        />
    );
}, isEqual);
