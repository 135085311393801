import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import {
    type PlaylistExamEvaluationIndexResponse,
    type PlaylistExamEvaluationShowResponse,
} from './PlaylistExamEvaluation.types';

const PlaylistChallengeTutorBotRecordTagType = 'PlaylistChallengeTutorBotRecord';

export const playlistExamEvaluationsApi = createApi('playlistExamEvaluationsApi', {
    ...getBackRoyalApiBaseConfig('playlistExamEvaluationsApi'),
    tagTypes: [PlaylistChallengeTutorBotRecordTagType],
    endpoints: builder => ({
        getExamEvaluations: builder.query<PlaylistExamEvaluationIndexResponse, void>({
            query: () => ({
                url: 'playlist_exam_evaluations.json',
                method: 'GET',
            }),
        }),
        getExamEvaluation: builder.query<PlaylistExamEvaluationShowResponse, string>({
            query: id => ({
                url: `playlist_exam_evaluations/${id}.json`,
                method: 'GET',
            }),
            providesTags: result =>
                // we tag it with the id of each PlaylistChallengeTutorBotRecord, so when one of them
                // gets ignored/unignored via the mutation below, we invalidate the whole PlaylistExamEvaluation
                // and force a refetch of this query.
                result?.contents?.playlistExamEvaluations?.flatMap(playlistExamEvaluation =>
                    playlistExamEvaluation.examFrameEntries?.flatMap(frameEntry =>
                        frameEntry.challenges?.map(playlistChallengeTutorBotRecord => ({
                            type: PlaylistChallengeTutorBotRecordTagType,
                            id: playlistChallengeTutorBotRecord.id,
                        })),
                    ),
                ) || [],
        }),
        // Note that this API method is actually specific to a PlaylistChallengeTutorBotRecord, not a PlaylistExamEvaluation.
        updateChallengeIgnoredValue: builder.mutation<
            void,
            { playlistChallengeTutorBotRecordId: string; ignored: boolean }
        >({
            query: ({ playlistChallengeTutorBotRecordId, ignored }) => ({
                url: `playlist_exam_evaluations/update_challenge_ignored_value.json`,
                method: 'PUT',
                body: { playlistChallengeTutorBotRecordId, ignored },
            }),
            // calling this mutation will trigger a subsequent refetch of the playlist exam evaluation
            invalidatesTags: (_result, _error, { playlistChallengeTutorBotRecordId }) => [
                { type: PlaylistChallengeTutorBotRecordTagType, id: playlistChallengeTutorBotRecordId },
            ],
        }),
    }),
});
