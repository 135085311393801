import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { type SendableMessageType } from '@sendbird/uikit-react/types/utils';
import { SendingStatus } from '@sendbird/chat/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons/faCheckDouble';
import clsx from 'clsx';

const classes = (read: boolean) =>
    clsx('text-[14px]', 'me-[5px]', 'my-auto', read ? 'quantic:text-coral valar:text-blue' : 'text-slate-grey');

export const ReadReceipt = ({ message }: { message: SendableMessageType }) => {
    const { currentChannel } = useGroupChannelContext();
    const deliveredToAll = currentChannel?.getUndeliveredMemberCount?.(message) === 0;
    const readByAll = currentChannel?.getUnreadMemberCount?.(message) === 0;

    if (message.sendingStatus !== SendingStatus.SUCCEEDED || !deliveredToAll) return null;

    return <FontAwesomeIcon className={classes(readByAll)} icon={faCheckDouble} />;
};
