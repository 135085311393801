import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Stream } from 'Lessons';
import { type CoverageMaps } from './CoverageMaps.types';
import { ShowStreamCoverage } from './ShowStreamCoverage';

type Props = {
    streams: Stream<true>[];
    examStream: Stream<true>;
    coverageMaps: CoverageMaps;
};

export function ShowAllStreamsCoverage({ streams, examStream, coverageMaps }: Props) {
    return (
        <>
            <p className="mb-6 ms-2 text-xs text-gray-600">
                <FontAwesomeIcon icon={faInfoCircle} className="-mt-[2px] me-1 inline-block" />{' '}
                <span>
                    Learning objectives are pulled from lesson descriptions. Core concepts are automatically generated.
                </span>
            </p>
            {streams!.map(
                stream =>
                    !stream.exam && (
                        <ShowStreamCoverage
                            key={stream.id}
                            stream={stream}
                            examStream={examStream!}
                            coverageMaps={coverageMaps}
                        />
                    ),
            )}
        </>
    );
}
