import { type BaseMessage } from '@sendbird/chat/message';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type AnyObject } from '@Types';
import deepEqual from 'deep-equal';
import { type CareerProfile } from 'CareerProfiles';
import { type SerializedChannel, type MessagingSlice } from './messaging.types';

const initialState: MessagingSlice = {
    activeChannelUrl: '',
    replyMessageId: null,
    showChannelInfo: false,
    showProfileUserId: null,
    showMessageSearch: false,
    targetSearchMessage: null,
    highlightedMessageId: null,
    openChat: false,
    loading: false,
    showNewMessage: false,
    presetNewMessageRecipient: null,
    userChannelMap: null,
    channelsByUrl: {},
    usersToMessage: null,
};

export const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        reset(_state) {
            return initialState;
        },
        setActiveChannelUrl(state, action: PayloadAction<string>) {
            state.showNewMessage = !action.payload;
            state.activeChannelUrl = action.payload;
        },
        setReplyMessageId(state, action: PayloadAction<number | null>) {
            state.replyMessageId = action.payload;
        },
        setShowChannelInfo(state, action: PayloadAction<boolean>) {
            state.showChannelInfo = action.payload;
        },
        setShowProfileUserId(state, action: PayloadAction<string | null>) {
            state.showProfileUserId = action.payload;
        },
        setShowMessageSearch(state, action: PayloadAction<boolean>) {
            state.showMessageSearch = action.payload;
        },
        setTargetSearchMessage(state, action: PayloadAction<Pick<BaseMessage, 'messageId' | 'createdAt'> | null>) {
            state.targetSearchMessage = action.payload;
        },
        setHighlightedMessageId(state, action: PayloadAction<number | null>) {
            state.highlightedMessageId = action.payload;
        },
        setOpenChat(state, action: PayloadAction<boolean>) {
            state.openChat = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setShowNewMessage(state, action: PayloadAction<boolean>) {
            if (action.payload) {
                state.activeChannelUrl = '';
            }
            state.showNewMessage = action.payload;
        },
        setPresetNewMessageRecipient(
            state,
            action: PayloadAction<{ id: string; profile: CareerProfile; serializedUser: AnyObject } | null>,
        ) {
            state.presetNewMessageRecipient = action.payload;
            if (action.payload && state.usersToMessage) {
                state.usersToMessage.profilesById[action.payload.id] = action.payload.profile;
            }
        },
        setUserChannelMap(state, action: PayloadAction<AnyObject<string> | null>) {
            if (!deepEqual(state.userChannelMap, action.payload)) {
                state.userChannelMap = action.payload;
            }
        },
        registerChannel(state, action: PayloadAction<SerializedChannel>) {
            state.channelsByUrl[action.payload.url] = action.payload;
        },
        setUsersToMessage(
            state,
            action: PayloadAction<{
                availableUsers: AnyObject[];
                suggestedUsers: AnyObject[];
                profilesById: AnyObject<CareerProfile>;
            }>,
        ) {
            state.usersToMessage = action.payload;
        },
        setAvailableUsers(state, action: PayloadAction<AnyObject[]>) {
            if (!state.usersToMessage) return;

            state.usersToMessage.availableUsers = action.payload;
        },
        addAvailableUser(state, action: PayloadAction<{ user: AnyObject; profile: CareerProfile }>) {
            const { profile, user } = action.payload;
            if (!state.usersToMessage) return;

            if (!state.usersToMessage.availableUsers.find(u => u.userId === user.userId)) {
                state.usersToMessage.availableUsers.push(user);
            }
            state.usersToMessage.profilesById[profile.userId] = profile;
        },
        addSuggestedUser(state, action: PayloadAction<AnyObject>) {
            const user = action.payload;

            if (!state.usersToMessage) return;

            if (!state.usersToMessage.suggestedUsers.find(u => u.userId === user.userId)) {
                state.usersToMessage.suggestedUsers.unshift(user);
            }
        },
    },
});

export const { actions } = messagingSlice;
