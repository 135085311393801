import { type ContextItemPosition } from '../messaging.types';

/**
 * Calculate the position of the emoji picker based on a click event
 * and the container element. If no container is provided, the window is used.
 */
export function calculateContextItemPosition(
    triggerElement: Element,
    containerElement?: Element,
    options?: {
        buffer?: number;
        maxWidth?: number;
    },
): ContextItemPosition {
    const { buffer = 10, maxWidth = 300 } = options || {};
    // Get the safe area insets to account for notches, browser UI, etc.
    const safeAreaInsets = getSafeAreaInsets();

    // Get the bounding rect of the trigger element and the container element.
    const trigger = triggerElement.getBoundingClientRect();
    // If no container is provided, use the window.
    const container = containerElement
        ? containerElement.getBoundingClientRect()
        : new DOMRect(0, 0, window.innerWidth, window.innerHeight);

    const leftSpace = trigger.right - container.left;
    const rightSpace = container.right - trigger.left;
    const topSpace = trigger.top - container.top;
    const bottomSpace = container.bottom - trigger.bottom;

    const positions = {
        topLeft: {
            height: topSpace - buffer,
            width: maxWidth,
            bottom: window.innerHeight - trigger.top - safeAreaInsets.bottom,
            right:
                leftSpace < maxWidth
                    ? window.innerWidth - trigger.right - (maxWidth - leftSpace)
                    : window.innerWidth - trigger.right,
        },
        bottomLeft: {
            height: bottomSpace - buffer,
            width: maxWidth,
            top: trigger.bottom - safeAreaInsets.top,
            right:
                leftSpace < maxWidth
                    ? window.innerWidth - trigger.right - (maxWidth - leftSpace)
                    : window.innerWidth - trigger.right,
        },
        topRight: {
            height: topSpace - buffer,
            width: maxWidth,
            bottom: window.innerHeight - trigger.top - safeAreaInsets.bottom,
            left: rightSpace < maxWidth ? trigger.left - (maxWidth - rightSpace) : trigger.left,
        },
        bottomRight: {
            height: bottomSpace - buffer,
            width: maxWidth,
            top: trigger.bottom - safeAreaInsets.top,
            left: rightSpace < maxWidth ? trigger.left - (maxWidth - rightSpace) : trigger.left,
        },
    };

    // Determine the best position based on available space.
    if (leftSpace > rightSpace) return topSpace > bottomSpace ? positions.topLeft : positions.bottomLeft;
    return topSpace > bottomSpace ? positions.topRight : positions.bottomRight;
}

function getSafeAreaInsets() {
    // Most widely compatible method to get safe area insets to account for notches, browser UI, etc.
    const style = getComputedStyle(document.documentElement);
    return {
        // These CSS variables are defined on :root in Messaging.scss
        top: parseInt(style.getPropertyValue('--safe-area-inset-top') || '0', 10),
        bottom: parseInt(style.getPropertyValue('--safe-area-inset-bottom') || '0', 10),
    };
}
