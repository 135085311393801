/* eslint-disable max-lines-per-function */
import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useChannelMetaData, useMessagingConfig, useGetChannelName } from '../utils';
import { type SerializedChannel } from '../messaging.types';
import {
    setActiveChannelUrl,
    setShowChannelInfo,
    setShowMessageSearch,
    setShowNewMessage,
    setShowProfileUserId,
} from '../actions';
import { MemberAvatars } from './MemberAvatars';

const classes = {
    left: {
        mobileBackButton: clsx(
            'sm:hidden',
            'me-3',
            'flex',
            'items-center',
            'justify-center',
            'hover:bg-slate-grey-lightest',
            'rounded-lg',
            'h-[40px]',
            'w-[40px]',
            '-ms-[15px]',
        ),
        avatars: {
            container: clsx('h-[40px]', 'w-[40px]'),
            indicatorOuter: clsx('w-[12px]', 'h-[12px]'),
            indicatorInner: clsx('min-w-[9px]', 'min-h-[9px]'),
        },
    },
    middle: {
        button: clsx(
            'flex',
            'cursor-pointer',
            'items-center',
            'justify-start',
            'p-2',
            'hover:bg-slate-grey-lightest',
            'rounded-lg',
        ),
        channelName: clsx(
            'line-clamp-2',
            'overflow-ellipsis',
            'text-left',
            'text-lg',
            'text-black',
            'font-semibold',
            'leading-[1]',
        ),
    },
    right: {
        container: clsx('flex', 'items-center'),
        search: {
            button: clsx(
                'h-[40px]',
                'w-[40px]',
                'flex',
                'items-center',
                'justify-center',
                'rounded-lg',
                'hover:bg-slate-grey-lightest',
                'sm:-me-[5px]',
                '-me-[3px]',
            ),
            icon: clsx('text-[20px]', 'text-black'),
        },
    },
};

export const ChannelHeader = () => {
    const dispatch = useDispatch();
    const { userId } = useMessagingConfig();
    const { currentChannel } = useGroupChannelContext();
    const metaData = useChannelMetaData(currentChannel);
    const getChannelName = useGetChannelName();

    const channelType = metaData?.channelType;

    const handleChannelNameClick = useCallback(() => {
        if (!currentChannel || !channelType) return;

        if (channelType === 'group') {
            dispatch(setShowProfileUserId(null));
            dispatch(setShowChannelInfo(true));
            return;
        }

        if (channelType === '1-1') {
            const otherMember = currentChannel.members.find(member => member.userId !== userId);
            if (!otherMember) return;
            dispatch(setShowProfileUserId(otherMember.userId));
        }
    }, [dispatch, channelType, currentChannel, userId]);

    const handleSearchClick = useCallback(() => {
        dispatch(setShowMessageSearch(true));
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
    }, [dispatch]);

    const channelName = useMemo(() => {
        if (!currentChannel || !userId) return null;
        return getChannelName(currentChannel.serialize() as SerializedChannel, userId);
    }, [currentChannel, userId, getChannelName]);

    const membersForAvatar = useMemo(
        () => currentChannel?.members.filter(member => member.userId !== userId) ?? [],
        [userId, currentChannel],
    );

    if (!channelName || !currentChannel) return null;

    return (
        <GroupChannelHeader
            renderLeft={() => (
                <>
                    <button
                        type="button"
                        className={classes.left.mobileBackButton}
                        onClick={() => {
                            dispatch(setActiveChannelUrl(''));
                            dispatch(setShowNewMessage(false));
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button type="button" onClick={handleChannelNameClick} title={channelName}>
                        <MemberAvatars
                            className={classes.left.avatars.container}
                            onlineIndicatorOuterClassName={classes.left.avatars.indicatorOuter}
                            onlineIndicatorInnerClassName={classes.left.avatars.indicatorInner}
                            members={membersForAvatar}
                            showOnlineStatus
                        />
                    </button>
                </>
            )}
            renderMiddle={() => (
                <div>
                    <button
                        type="button"
                        className={classes.middle.button}
                        onClick={handleChannelNameClick}
                        title={channelName}
                    >
                        <span className={classes.middle.channelName}>{channelName}</span>
                    </button>
                </div>
            )}
            renderRight={() => (
                <div className={classes.right.container}>
                    <button type="button" className={classes.right.search.button} onClick={handleSearchClick}>
                        <FontAwesomeIcon icon={faSearch} className={classes.right.search.icon} />
                    </button>
                </div>
            )}
        />
    );
};
