import { sendBirdSelectors } from '@sendbird/uikit-react';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { type CareerProfile } from 'CareerProfiles';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AnyObject } from '@Types';
import { promiseCacheFactory } from 'PromiseCacheFactory';
import { getSerializedUsersToMessage } from '../selectors';
import { setUsersToMessage } from '../actions';
import { useCreateUserPromiseFromIds } from './useCreateUserPromiseFromIds';

const [promiseCache] = promiseCacheFactory();

const useCreateUsersToMessagePromise = ({
    userIds,
    recommendedProfiles,
    usersWithExistingMessages,
    allProfiles,
}: {
    userIds: string[];
    recommendedProfiles: CareerProfile[];
    usersWithExistingMessages: string[];
    allProfiles: CareerProfile[];
}) => {
    const dispatch = useDispatch();

    const createUserPromiseArray = useCreateUserPromiseFromIds(userIds);

    const createUsersToMessagePromise = useCallback(
        () =>
            Promise.all(createUserPromiseArray()).then((...userArraysArray) => {
                const users = userArraysArray.flat().flatMap(a => a.map(u => u));

                const updatedUsersToMessage = {
                    availableUsers: users
                        .map(user => (userIds.find(userId => userId === user.userId) ? user.serialize() : null))
                        .filter(v => !!v) as AnyObject[],
                    suggestedUsers: users
                        .map(user =>
                            // combine the recommended users and the users we have messages with already to create the
                            // "suggested users"
                            recommendedProfiles
                                .map(p => p.userId)
                                .concat(usersWithExistingMessages)
                                .find(id => id === user.userId)
                                ? user.serialize()
                                : null,
                        )
                        .filter(v => !!v) as AnyObject[],
                    profilesById: allProfiles.reduce<AnyObject<CareerProfile>>(
                        (prev, curr) => ({ ...prev, [curr.userId]: curr }),
                        {},
                    ),
                };

                dispatch(setUsersToMessage(updatedUsersToMessage));
            }),
        [allProfiles, createUserPromiseArray, dispatch, recommendedProfiles, userIds, usersWithExistingMessages],
    );

    return createUsersToMessagePromise;
};

export const useGetUsersAndCareerProfiles = ({
    recommendedProfiles,
    relevantUserProfiles,
    usersWithExistingMessages,
}: {
    recommendedProfiles: CareerProfile[];
    relevantUserProfiles: CareerProfile[];
    usersWithExistingMessages: string[];
}) => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);
    const serializedUsersToMessage = useSelector(getSerializedUsersToMessage);

    const usersToMessage = useMemo(
        () => ({
            availableUsers: serializedUsersToMessage?.availableUsers.map(u => sdk.buildUserFromSerializedData(u)) || [],
            suggestedUsers: serializedUsersToMessage?.suggestedUsers.map(u => sdk.buildUserFromSerializedData(u)) || [],
            profilesById: serializedUsersToMessage?.profilesById || {},
        }),
        [
            sdk,
            serializedUsersToMessage?.availableUsers,
            serializedUsersToMessage?.profilesById,
            serializedUsersToMessage?.suggestedUsers,
        ],
    );

    const allProfiles = useMemo(
        () =>
            relevantUserProfiles
                .concat(recommendedProfiles)
                // remove duplicates and ensure that the user has messaging enabled
                .filter(
                    (p, i, arr) =>
                        arr.findIndex(p2 => p2.userId === p.userId) === i &&
                        !!p.messagingEnabled &&
                        p.hasEverHadSendbirdAccess,
                ),

        [recommendedProfiles, relevantUserProfiles],
    );

    const userIds = useMemo(() => allProfiles.map(cp => cp.userId), [allProfiles]);

    const createUsersToMessagePromise = useCreateUsersToMessagePromise({
        userIds,
        recommendedProfiles,
        usersWithExistingMessages,
        allProfiles,
    });

    if (!serializedUsersToMessage) {
        const promise = promiseCache(JSON.stringify(userIds.sort()), createUsersToMessagePromise());
        throw promise;
    }

    return usersToMessage;
};
