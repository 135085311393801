import { memo, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useGetStudentsToMessage } from './useGetStudentsToMessage';

const PreloadUserDataComponent = memo(() => {
    useGetStudentsToMessage();

    return null;
});

// This component exists just to give us a place to call useGetStudentsToMessage
// before we even open the modal and try to prevent any loading screens
// We don't care about it's loading state or if it errors since we are not showing anything yet
export const PreloadUserData = memo(() => (
    <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>
            <PreloadUserDataComponent />
        </Suspense>
    </ErrorBoundary>
));
