import { useCurrentUser } from 'FrontRoyalAngular';
import { useConfig } from 'FrontRoyalConfig';

const colorSetQuantic = {
    '--sendbird-light-primary-500': '#a52e3b',
    '--sendbird-light-primary-400': '#dd394c',
    '--sendbird-light-primary-300': '#ff4d63',
    '--sendbird-light-primary-200': '#ff8d9a',
    '--sendbird-light-primary-100': '#ffced3',
} as const;

export const useMessagingConfig = () => {
    const config = useConfig();
    const appId = config?.sendbirdApplicationId();
    const currentUser = useCurrentUser();
    // TODO: handle colorset based off of program type
    return {
        appId,
        accessToken: currentUser?.sendbirdAccessToken || undefined,
        userId: currentUser?.id,
        colorSet: colorSetQuantic,
    };
};
