
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.mobileFileUpload = window.Smartly.locales.modules.en.back_royal.mobileFileUpload || {};
window.Smartly.locales.modules.en.back_royal.mobileFileUpload.mobileFileUpload = window.Smartly.locales.modules.en.back_royal.mobileFileUpload.mobileFileUpload || {};
window.Smartly.locales.modules.en.back_royal.mobileFileUpload.mobileFileUpload = {...window.Smartly.locales.modules.en.back_royal.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "Cancel",
    "photo": "Photo",
    "fromPhotos": "From Photos",
    "takePicture": "Take Picture"
}
}
    