import React, { type FC, useState } from 'react';
import { type MessageContentProps } from '@sendbird/uikit-react/ui/MessageContent';
import clsx from 'clsx';
import { MessageReactionsMenu } from './MessageReactionsMenu';
import { MessageContextMenu } from './MessageContextMenu';

const classes = {
    wrapper: (otherUser: boolean) =>
        clsx(
            'flex',
            'items-center',
            'mx-[13px]',
            'absolute',
            'top-[calc(50%-20px)]',
            otherUser ? 'right-[-107px]' : 'left-[-107px]',
        ),
};

type MenusProps = { isFromOtherUser: boolean } & Omit<MessageContentProps, 'className'>;

export const Menus: FC<MenusProps> = ({ message, isFromOtherUser, ...rest }) => {
    const [selectedIcon, setSelectedIcon] = useState('');

    if (message.isAdminMessage()) return null;

    return (
        <div className={classes.wrapper(isFromOtherUser)}>
            <MessageReactionsMenu
                message={message}
                open={selectedIcon === 'emoji'}
                onClickButton={() => {
                    setSelectedIcon(selectedIcon === 'emoji' ? '' : 'emoji');
                }}
            />
            <MessageContextMenu
                open={selectedIcon === 'menu'}
                message={message}
                messageIsFromOtherUser={isFromOtherUser}
                onClickButton={() => {
                    setSelectedIcon(selectedIcon === 'menu' ? '' : 'menu');
                }}
                onClickMenuItem={() => {
                    // Close the menu after making a selection
                    setSelectedIcon('');
                }}
                {...rest}
            />
        </div>
    );
};
