import { type ApplicationUserListQuery, type User } from '@sendbird/chat';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { useCallback, useMemo } from 'react';

const recursivelyFetchUsers = (query: ApplicationUserListQuery, onComplete: (users: User[]) => void) => {
    const fetchUsers = (q: typeof query, users: User[] = []) => {
        if (q.hasNext) {
            q.next().then(sendbirdUsers => fetchUsers(q, [...users, ...sendbirdUsers]));
        } else {
            onComplete(users);
        }
    };

    fetchUsers(query);
};

export const useCreateUserPromiseFromIds = (userIds: string[]) => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);

    // It is possible that we end up having so many userIds
    // that the call to sendbird fails because the query param string gets too long
    // so we split the userIds into chunks of 200 and make concurrent api calls and
    // then combine the results
    const chunkedUserIds = useMemo(
        () =>
            userIds.reduce<string[][]>(
                (prev, id, i) => {
                    const index = Math.floor(i / 200);
                    if (prev[index]) prev[index].push(id);
                    else prev[index] = [id];

                    return prev;
                },
                [[]],
            ),
        [userIds],
    );

    const createUserPromiseArray = useCallback(
        () =>
            chunkedUserIds.map(ids => {
                const usersQuery = sdk.createApplicationUserListQuery({ userIdsFilter: ids });

                return new Promise<User[]>(r => {
                    recursivelyFetchUsers(usersQuery, users => r(users));
                });
            }),
        [chunkedUserIds, sdk],
    );

    return createUserPromiseArray;
};
