import { memo, type FC } from 'react';
import { isEqual } from 'lodash/fp';
import { faCircleExclamationCheck } from '@fortawesome/pro-regular-svg-icons/faCircleExclamationCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Props = { className?: string };

export const IgnoredWarningIcon: FC<Props> = memo(
    ({ className }: Props) => (
        <FontAwesomeIcon
            data-testid="ignored-warning-icon"
            className={clsx('inline-block text-gray-400', className)}
            icon={faCircleExclamationCheck}
        />
    ),
    isEqual,
);
