import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { Fade } from '@mui/material';
import { type RefObject, useCallback, useEffect, useRef, useState } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import {
    getActiveChannelUrl,
    getShowChannelDetailsPanel,
    getShowMessageSearch,
    getTargetSearchMessage,
} from '../selectors';
import { DateSeparator } from './DateSeparator';
import { MessageContent } from './MessageContent';
import { MessageInputInContext } from './MessageInput';
import { ChannelHeader } from './ChannelHeader';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { ChannelDetailsPanel } from './ChannelDetailsPanel';
import { setActiveChannelUrl, setHighlightedMessageId, setTargetSearchMessage, setShowNewMessage } from '../actions';
import { MessageSearch } from './MessageSearch';
import { MessageList } from './MessageList';

const classes = {
    wrapper: clsx('sm:flex', 'w-full'),
    channel: (showChannelDetailsPanel: boolean, showMessageSearch: boolean) =>
        clsx(
            { hidden: showMessageSearch },
            {
                'w-full': !showChannelDetailsPanel,
                'md:block hidden': showChannelDetailsPanel,
            },
            'h-full',
            'sm:transition-all',
            'grow',
        ),
    channelDetails: {
        container: (showChannelDetailsPanel: boolean) =>
            clsx('z-10', 'border-s', 'border-slate-grey-light', 'transition-all', {
                'w-0 h-0': !showChannelDetailsPanel,
                'w-full h-full md:basis-[300px] md:max-w-[300px] md:grow-0 md:shrink-0': showChannelDetailsPanel,
            }),
        fade: clsx('h-full'),
        innerWrapper: clsx('h-full'),
    },
};

export const Channel = () => {
    const dispatch = useDispatch();
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const showChannelDetailsPanel = useSelector(getShowChannelDetailsPanel);
    const [messageRefs, setMessageRefs] = useState<Record<number, RefObject<HTMLDivElement | null> | null>>({});
    const showMessageSearch = useSelector(getShowMessageSearch);
    const targetSearchMessage = useSelector(getTargetSearchMessage);
    const ChannelContainer = useRef<HTMLDivElement>(null);

    // If there is a selected message from message search,
    // poll until the target message is rendered, then scroll to it;
    // see also GroupChannel `startingPoint` prop
    useEffect(() => {
        if (!targetSearchMessage) return undefined;

        let timeout: NodeJS.Timeout;

        const interval = setInterval(() => {
            const targetSearchMessageEl = messageRefs[targetSearchMessage.messageId]?.current;
            if (!targetSearchMessageEl) return;

            targetSearchMessageEl.scrollIntoView({ block: 'center', behavior: 'instant' });
            dispatch(setHighlightedMessageId(targetSearchMessage.messageId));

            // Wait before unsetting in order to highlight the target message for a moment
            timeout = setTimeout(() => {
                dispatch(setTargetSearchMessage(null));
                dispatch(setHighlightedMessageId(null));
            }, 1500);
        }, 500);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [targetSearchMessage, dispatch, messageRefs]);

    const handleMessageRefSet = useCallback((messageId: number, ref: RefObject<HTMLDivElement | null> | null) => {
        setMessageRefs(prev => ({ ...prev, [messageId]: ref }));
    }, []);

    return (
        <ChannelWrapperContext.Provider value={ChannelContainer}>
            <div className={classes.wrapper} ref={ChannelContainer}>
                <div className={classes.channel(showChannelDetailsPanel, showMessageSearch)}>
                    <GroupChannel
                        channelUrl={activeChannelUrl}
                        renderChannelHeader={() => <ChannelHeader />}
                        renderPlaceholderLoader={() => <FrontRoyalSpinner className="h-full" color="force-brand" />}
                        onBackClick={() => {
                            dispatch(setActiveChannelUrl(''));
                            dispatch(setShowNewMessage(false));
                        }}
                        renderCustomSeparator={DateSeparator}
                        renderMessageContent={p => <MessageContent {...p} onRefSet={handleMessageRefSet} />}
                        renderMessageInput={() => <MessageInputInContext />}
                        startingPoint={targetSearchMessage?.createdAt ? targetSearchMessage.createdAt - 1 : undefined}
                        renderMessageList={p => <MessageList {...p} />}
                    />
                </div>
                <div className={classes.channelDetails.container(showChannelDetailsPanel)}>
                    <Fade in timeout={{ appear: 150, exit: 0 }} className={classes.channelDetails.fade}>
                        <div className={classes.channelDetails.innerWrapper}>
                            <ChannelDetailsPanel />
                        </div>
                    </Fade>
                </div>
                {showMessageSearch && <MessageSearch />}
            </div>
        </ChannelWrapperContext.Provider>
    );
};
