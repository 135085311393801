import { createSelector } from '@reduxjs/toolkit';
import { type MessagingSlice } from './messaging.types';

type State = { messaging: MessagingSlice };

export const getActiveChannelUrl = (state: State) => state.messaging.activeChannelUrl;
export const getReplyMessageId = (state: State) => state.messaging.replyMessageId;
export const getShowMessageSearch = (state: State) => state.messaging.showMessageSearch;
export const getTargetSearchMessage = (state: State) => state.messaging.targetSearchMessage;
export const getHighlightedMessageId = (state: State) => state.messaging.highlightedMessageId;
export const getOpenChat = (state: State) => state.messaging.openChat;
export const getLoading = (state: State) => state.messaging.loading;
export const getShowNewMessage = (state: State) => state.messaging.showNewMessage;
export const getPresetNewMessageRecipient = (state: State) => state.messaging.presetNewMessageRecipient;
export const getShowChannelInfo = (state: State) => state.messaging.showChannelInfo;
export const getShowProfileUserId = (state: State) => state.messaging.showProfileUserId;
export const getShowChannelDetailsPanel = (state: State) =>
    state.messaging.showChannelInfo || !!state.messaging.showProfileUserId;
export const getUserChannelMap = (state: State) => state.messaging.userChannelMap;
export const getChannelsByUrl = (state: State) => state.messaging.channelsByUrl;

export const getSerializedUsersToMessage = (state: State) => state.messaging.usersToMessage;

export const getAvailableUserIds = createSelector(
    [getSerializedUsersToMessage],
    usersToMessage => (usersToMessage ? usersToMessage.availableUsers.map(u => u.userId).sort() : []) as string[],
);

export const getUsersFromUserChannelMap = createSelector(
    [getUserChannelMap, (_, currentUserId = '') => currentUserId],
    (channelMap, currentUserId) =>
        channelMap
            ? Object.keys(channelMap)
                  .reduce<string[]>((prev, curr) => [...prev, ...(JSON.parse(curr) as string[])], [])
                  // remove any duplicate and make sure the currentUser is not included
                  .filter((id, i, arr) => arr.findIndex(id2 => id2 === id) === i && id !== currentUserId)
            : [],
);
export const getChannelFromUserChannelMap = createSelector(
    [getUserChannelMap, (_, userIds: string[]) => userIds],
    (channelMap, userIds) => (channelMap ? channelMap[JSON.stringify(userIds.sort())] : undefined),
);
